import React from 'react'
import {ContentTitle} from '../../styles/Content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useSoundEffect } from './Sounds';
export default function Title(props) {
  const history=useHistory()
  const {click} = useSoundEffect();
  const goToBack=()=>{
    click();
    history.goBack()
  }
  return (
    <ContentTitle>
      <h1>{props.text}</h1>
      <span>
        {
          props.icon ?
          props.icon
          : null
        }
        {
          props.back!=undefined && props.back!=null && props.back==false ? 
          null
          :
          <Button style={{marginLeft:20}} variant="contained" color="secondary" 
            onClick={()=>{
              goToBack();
            }}
            > 
            <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
          </Button>
        }
      </span>
    </ContentTitle>
  )
}
