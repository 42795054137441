import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'

import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCogs, faEdit, faFolderPlus, faHome, faTimes, faPlus, faTimesCircle, faBan, faTicketAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'
import Checkbox from '@mui/material/Checkbox';
import Button from '@material-ui/core/Button';

import FormInput from '../../components/Form/Input'
import axios from 'axios'
import EditButton from '../../components/Form/EditButton';

import { toast } from 'react-toastify'
import { useSoundEffect } from '../../components/Content/Sounds'
import { Helmet } from 'react-helmet';
export default function EquipmentOption(props) {
  
  const {successPlay,errorPlay} = useSoundEffect()

  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)
  const [required,setRequired]=useState(false)
  const [columnName,setColumnName]=useState('')

  const [newValue,setNewValue]=useState('')
  const [equipmentType, setEquipmentType] = useState('')
  const [selectedColumn, setSelectedColumn] = useState('')
  const [columnSelector, setColumnSelector] = useState('')

  const [valueType,setValueType]=useState('')

  const [columns,setColumns]=useState([])
  const [values,setValues]=useState([])
  const [storedValue,setStoredValue]=useState([])

  const getColumns=(optId)=>{
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_EQUIPMENT_COLUMNS.replace('-type-',optId)),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token') 
      }
    })
    .then(res=>{
      setColumns(res.data.data)
    })
  }
  const getValues= (col,type)=>{
   
      axios({
        method: 'GET',
        url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_EQUIPMENT_VALUES.replace('-type-',type).replace('-columnId-',col.id)),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        }
      })
      .then( res=>{
        setValues(res.data.data)
        localStorage.setItem('storedval',JSON.stringify(res.data.data))
      })

   
    
  
  }
  const getEquipmentType=(type)=>{
    switch(type){
      case 'Jeneratör Seti':
        return 1
      case 'Aydınlatma Kulesi':
        return 2
      case 'Yük Bankası':
        return 3
      case 'Transfer Panosu':
        return 4
      case 'Dağıtım Panosu':
        return 5
      case 'Yakıt Tankı':
        return 6
      case 'Trafo':
        return 7

      default:
        return false
    }
  }
  useEffect(() => {
    if(equipmentType!='' && getEquipmentType(equipmentType)!=false){
        setColumns([])
        setEdit(false)
        getColumns(getEquipmentType(equipmentType))
    }
  }, [equipmentType])
  
  useEffect(() => {
    if(columns.find(x=>x.displayName.toLowerCase().replace(/\s/g,'')==columnSelector.toLowerCase().replace(/\s/g,''))){
      //console.log()
      const selected=columns.find(x=>x.displayName.toLowerCase().replace(/\s/g,'')==columnSelector.toLowerCase().replace(/\s/g,''));
      setValueType(selected.valueType)
      setSelectedColumn(selected.displayName)
      setEdit(false)
      getValues(selected,getEquipmentType(equipmentType))
      setRequired(selected.required)
      setColumnName(selected.displayName)
    }else{
      setColumnName('')
      setRequired(false)
      setValues([])
      setEdit(false)
      setSelectedColumn('')
    }

    
  }, [columnSelector])

  const updateOption=()=>{
    if(edit){
      if(columnName!=''){
        const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
        axios({
          method: 'POST',
          url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_UPDATE_EQUIPMENT_COLUMN.replace('-type-',getEquipmentType(equipmentType)).replace('-id-',columns.find(col=>col.displayName==selectedColumn).id)),
          headers:{
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          },
          data:{
            displayName:columnName,
            required:required
          }
        })
        .then(response=>{
          toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
          successPlay()
          setEdit(false)
        })
        .catch(error=>{
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
      }else{
        toast.error("Lütfen değer giriniz")
        errorPlay()
      }
    }else{
      setEdit(!edit)
    }
  }
  useEffect(() => {
    
  } ,[edit])
  const addValue=()=>{
    if(newValue!=''){
      if(values.find(v=>v.displayName==newValue)){
        toast.error('Bu değer zaten mevcut')
        errorPlay()
      }else{
        const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
        axios({
          method: 'POST',
          url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_ADD_EQUIPMENT_VALUE.replace('-type-',getEquipmentType(equipmentType))),
          headers:{
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          },
          data:{
            columnId:columns.find(col=>col.displayName==selectedColumn).id,
            value:newValue,
          }
        })
        .then(res=>{
          toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
          getValues(columns.find(col=>col.displayName==selectedColumn),getEquipmentType(equipmentType))
          setNewValue('')
          successPlay()
          
        })
        .catch(error=>{
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
      }
    }else{
      toast.error('Lütfen değer giriniz')
      errorPlay()
    }
  }
  const deleteValue=(id)=>{
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'POST',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_EQUIPMENT_VALUE.replace('-type-',getEquipmentType(equipmentType)).replace('-id-',id)),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    })
    .then(res=>{
      successPlay()
      getValues(columns.find(col=>col.displayName==selectedColumn),getEquipmentType(equipmentType))
      toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
  const checkRequired=()=>{
    if(columns.find(col=>col.displayName==selectedColumn).required){
      return true
    }else{
      return false
    }
  }
  const cancelOption=()=>{
    setEdit(false)
    setNewValue('')
    setEquipmentType('')
    setSelectedColumn('')
    setColumnSelector('')
    setColumns([])
    setValues([])
  }
  const updateColumnValue=async (valId,val,equipment)=>{
    if(val[0].value==''){
      toast.error('Lütfen değer giriniz')
      errorPlay()
    }else{
      let response=null
      let set=await axios({
        method:'POST',
        url:process.env.REACT_APP_API_MAIN+((process.env.REACT_APP_API_UPDATE_EQUIPMENT_VALUE.replace('-type-',(equipment)).replace('-id-',valId)).replace('-value-',val[0].value)),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        }
      })
      .then(res=>{
        successPlay()
        toast.success(res.data.message)
        getValues(columns.find(col=>col.displayName==selectedColumn),getEquipmentType(equipmentType))
        response=true
      })
      .catch(error=>{
        errorPlay()
        toast.error(error.response.data.message)
      })
      return response
    }
  }
  const checkOld=(val)=>{
    try {
      if( values.length>0 && (JSON.parse(localStorage.getItem('storedval'))).length>0){
      
        if(  values.find(v=>v.id==val.id).value==(JSON.parse(localStorage.getItem('storedval'))).find(v=>v.id==val.id).value){
          
          return (
            <Button 
              onClick={()=>{
                deleteValue(val.id)
              }}
              variant='contained' disabled={!edit} color="secondary" ><FontAwesomeIcon icon={faTimes} /></Button>
          )
        }else{
          return (
            <>
            <Button 
              onClick={()=>{
                setValues(values.map(v=>{
                  if(v.id==val.id){
                    v.value=(JSON.parse(localStorage.getItem('storedval'))).find(sv=>sv.id==val.id).value
                  }
                  return v
                }))
              }}
              variant='contained' disabled={!edit} color="secondary" style={{marginRight:5}}><FontAwesomeIcon icon={faBan} /></Button>
              <Button 
              onClick={async ()=>{
                let check=await updateColumnValue(val.id,values.filter(v=>v.id==val.id),getEquipmentType(equipmentType))
                if(check!=null){
                  localStorage.setItem('storedval',JSON.stringify(values))
                }
              }}
              variant='contained' disabled={!edit} color="primary" ><FontAwesomeIcon icon={faSave} /></Button>
            </>
          )
        }
      }
    } catch (error) {
      
    }
    
  }
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Ekipman Seçenekleri</title>
      </Helmet>
      <Content sidebar={sidebar}>
        
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title text={<><FontAwesomeIcon icon={faCogs}  /> Ekipman Seçenekleri</>} icon={
          <>
          {
            selectedColumn!=''?
            <EditButton edit={edit} cancel={cancelOption} clicker={updateOption} />
            : null
          }
          </>} />
          <Accordion className='accordion-form' defaultExpanded={true} >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Ekipman Seçenek Düzenleme Formu</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>

                <div className='input-group m50'>
                  <span>Ekipman Türü :</span> 
                    <FormInput 
                    type={'combo'}
                    placeholder={'Ekipman Türü'}
                    setValue={setEquipmentType}
                    value={equipmentType}
                    children={['Jeneratör Seti','Aydınlatma Kulesi','Yük Bankası','Transfer Panosu','Dağıtım Panosu','Yakıt Tankı','Trafo']}
                    />
                </div>

                {
                  columns.length>0 ?
                  <>
                  <div className='input-group m50'>
                    <span>Kolon :</span> 
                      <FormInput 
                      type={'combo'}
                      placeholder={'Kolon'}
                      setValue={setColumnSelector}
                      value={columnSelector}
                      children={columns.map(col=>col.displayName)}
                      />
                      
                  </div>
                  </>
                  :
                  null
                }
                
                {
                  selectedColumn!=''  && columns.length>0 ?
                  <>
                  
                  
                  <div className='input-group m50'  >
                      <span >Kolon Adı : </span>
                      <FormInput
                        disabled={!edit}
                        value={columnName}
                        setValue={setColumnName}
                        placeholder={columns.find(col=>col.displayName.toLowerCase().replace(/\s/g, '')==selectedColumn.toLowerCase().replace(/\s/g, '')).displayName}
                      />
                  </div>
                  <div className='input-group m50'  >
                      <span >Zorunlu  </span>
                      <FormInput
                        type="checkbox"
                        checked={required ? true : false} disabled={!edit}
                        onChange={()=>setRequired(!required)}
                      />
                  </div>
                  <div className='input-group m50'  >
                      <span >Veri Tipi  </span>
                      <FormInput
                        type="text"
                        disabled
                        value={valueType}
                      />
                  </div>
                  

                  </>
                  :
                  <></>
                }
              </AccordionForm>
              {
                values.length>0 || (
                  selectedColumn!=''  && columns.length>0 ? 
                  (columns.find(col=>col.displayName==selectedColumn).valueType=='combo') :
                  false
                ) ?
                <Accordion
                  defaultExpanded={true}
                  style={{boxShadow:'0px 0px 12px 4px rgba(0,0,0,0.1)',borderRadius:10,marginTop:10}}
                  >
                    <AccordionSummary
                      style={{width:'100%',paddingLeft:10,paddingRight:10}}
                      expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
                      aria-controls="panel1a-content"
                      id="panel1a-sad"
                    >
                      <Typography>Kolon Seçenekleri <span style={{fontSize:13,marginLeft:17,fontStyle:'italic'}}>( Kolon seçenekleri anlık olarak işlem sonrası güncellenir! )</span> </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                          
                      <div>

                        <div className='edit-option'>

                        {
                          selectedColumn!=''  ?
                          
                          <div className='input-group' >
                            <FormInput
                              type={'text'}
                              placeholder={'Yeni Değer'}
                              value={newValue}
                              onKeyUp={(e)=>{
                                if(e.code=='Enter' || e.code=='NumpadEnter'){
                                  addValue(newValue)
                                }
                              }}
                              setValue={setNewValue}
                              rightIcon={
                              <>
                              <Button onClick={()=>addValue(newValue)} variant='contained' style={{backgroundColor:'#8BC34A'}} ><FontAwesomeIcon icon={faPlus} /></Button>
                              </>
                              }
                            />
                          </div>
                          
                        :null 
                        }
                        {
                          values.length>0 ?
                            

                            (
                              values.map(val=>{
                                return (
                                  <div className='input-group' key={val.id}>
                                    
                                    <FormInput
                                      type={'text'}
                                      placeholder={val.value}
                                      disabled={!edit}
                                      onChange={(e)=>{
                                        setValues(values.map(v=>{
                                          if(v.id==val.id){
                                            v.value=e.target.value
                                          }
                                          return v
                                        }));
                                      }}

                                      value={values.find(v=>{return v.id==val.id}).value}
                                      rightIcon={
                                      <>
                                      
                                      {
                                        checkOld(val) 
                                      }
                                      </>
                                      }
                                    />
                                  </div>

                                )
                              })
                            )
                          :null
                        }
                        </div>
                        
                      </div>

                  </AccordionDetails>
                  </Accordion>
                  :null
              }
          
              


            </AccordionDetails>
          </Accordion>

          {
            edit ?  
            <div className='addButton'>
              {/* <Button type='submit' color="primary" variant="contained">Kaydet</Button> */}
            </div>
            :null
          }

          </form>

        </ContentForm>

      </Content>
    </>
  )
}
