import styled from "styled-components";
import { rgba } from 'polished';
import yellowrepeater from '../images/yellow-repeater.jpg';
import whiterepeater from '../images/white-repeater.png';

import searchWall from '../images/search-wallpaper.png'

const Content = styled.div`
  width:calc(100% - ${props=>props.sidebar ? 300 : 0}px);
  transition: all 0.3s ease-in-out;
  margin-left:${props=> props.sidebar ? 300 : 0}px;
  position:relative;
  display:flex;
  @media only screen and (max-width: 800px) {
    width:100%;
    margin-left:0px;
  } 
  flex-direction:column;
  padding:60px 20px;
  padding-bottom:300px;
  justify-content:center;
  align-items:center;
  .addButton{
    top:10px;
    position:relative;
    width:100%;
    bottom:0;
    height:40px;
    button{
      position:absolute;
      height:100%;
      width:20%;
      right:0;
    }
    @media only screen and (max-width: 1000px) {
      button{
        width:50%;
      }
    }

  }
  
  .dot-menu-items{
    position:absolute;
    width:115%;
    left:-20px;
    top:calc(100% - 10px);
    right:0;
    z-index:1299;
    background:${({theme}) => theme.header};
    border-radius:10px;
    padding:10px 5px;
    display:flex;
    flex-direction:column;
    a{
      text-decoration:none;
      border-radius:10px;
      color:${({theme}) => theme.text};
      padding:10px;
      &:hover{
        background:#feca57;
        color:${({theme}) => theme.text};
      }
    }
  }
  .search-input{
    margin-left:-3px;
    background-color:#fff;
    padding-left:20px;
    width:101.5%;
    padding-right:20;
  }
  .filter-container{
    
  }
`

const ContentForm=styled.div`
  background-color:${({theme})=>theme.content};
  width:80%;
  border-radius:15px;
  padding:20px;
  min-height:50vh;
  .edit-option{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    margin-top:20px;
    flex-direction:column;
    .input-group{
      width:65%!important;
      div{
        width:100%!important;
      }
    }

  }
  .tab-btns{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    margin-bottom:20px;
    flex-wrap:wrap;
    .active{
      border-bottom:2px solid ${({theme})=>theme.text};
    }
    .tab-btn{
      display:flex;
      justify-content:space-between;
      .closer{
        border-radius:100%;
        width:25px;
        transition: all 0.3s ease-in-out;
        &:hover{
          background:${({theme})=>theme.text}!important;
          color:${({theme})=>theme.body}!important;
        }
      }
      &>button{
        padding-right:40px;
        
        
        
      }
    }
  }
  .home-flex{
    justify-content:space-between;
    
    &>div{
      &:not(:last-child){
        float:right;
      }
      
      margin-left:1%;
      
      margin-bottom:20px;
      width:calc(50% - 25px);
      border:1px solid #ddd;
      background-color:#fff;
      border-radius:15px;
      box-shadow:0px 0px 10px rgba(0,0,0,0.1);
      h1{
        font-size:19px;
        position:relative;
        &:hover{
          &::before{
            opacity:0.3;
          }
        }
        &::before{
          content:'';
          position:absolute;
          left:0;
          top:0;
          width:100%;
          transition:opacity 0.3s ease-in-out;
          height:100%;
          background:url(${yellowrepeater});
          opacity:0.2;
          border-top-left-radius:15px;
          border-top-right-radius:15px;
        }
        font-weight:500;
        cursor:pointer;
        height:30px;
        &::selection{
          background:transparent;
        }
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:calc(100% - 50px);
        text-select:none;
        padding:20px 25px;
        
      }
      ul{
        overflow-y:scroll;
        -ms-overflow-style: none;  
        scrollbar-width: none; 
        &::-webkit-scrollbar{
          display: none;
        }
        list-style:none;
        position:relative;
        max-height:200px;
        min-height:200px;
        overflow-y:scroll;
        background:url(${whiterepeater}) ;
        border-bottom-left-radius:15px;
        border-bottom-right-radius:15px;
        list-style-type:none;
        padding:20px 15px;
        display:flex;
        flex-direction:column;
        li{
          h3{
            font-size:14px;
            font-weight:600;
            float:left;
            margin-right:10px;
          }
          &:nth-child(2n+1){
            background-color:rgba(0,0,0,0.05);
          }
          font-size:14px;
          position:relative;
          z-index:2;
          font-weight:400;
          line-height:20px;
          padding:7px 15px;
          
          &::after{
            content:'';
            position:absolute;
            left:0;
            top:0;
            width:100%;
            transition:all 0.3s ease-in-out;
            height:100%;
            background:url(${yellowrepeater});
            opacity:0;
          }
          &::before{
            content:'';
            position:absolute;
            left:0;
            top:0;
            z-index:1;
            width:7px;
            height:100%;
            background:url(${yellowrepeater});
            opacity:0;
          }
          &:hover{
            &::before{
              opacity:0.3;
            }
            &::after{
              opacity:0.1;
            }
          }
        }
      }
      
    }
    .close-flex-item{
      ul{
        display:none;
      }
      h1{
        &::before{
          border-bottom-left-radius:15px;border-bottom-right-radius:15px;
        }
      }
      
    }
    @media only screen and (max-width: 1000px) {
      &>div{
        width:100%;
      }
    }
  }
  
  .accordion-form{
    background-color:${({theme})=>theme.acordion};
    color:${({theme})=>theme.text};
    
  }
  .input-group{
    width:28%;
    
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-right:5%;
    margin-bottom:2.5%;
    height:40px;
    @media only screen and (max-width:1450px){
      width:45%;
    }
    @media only screen and (max-width:950px){
      width:100%!important;
    }
  }
  .input-search{
    width:100%!important;
    div,input{
      width:100%!important;
    }
  }

  .m50{
    width:43%;
  }
  
  
`

const ContentTitle=styled.div`
  height:50px;
  
  padding:15px 0px;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between;
  border-bottom:3px solid ${({theme})=>rgba(theme.headerText,0.2)};
  padding-bottom:20px;
  margin-bottom:20px;
  &>span{
    width:65%;
    display:flex;
    justify-content:flex-end;
    button{
      height:45px;
    }
    svg{
      margin:5px;
    }
  }
  h1{
    width:30%;
    font-size:22px;
    font-weight:bold;
    color:${({theme})=>theme.text};
    padding-left:20px;
  }
  
  @media only screen and (max-width: 1360px) {
    position:relative;
    height:100px;
    
    &>span,h1{
      width:100%;
    }
    &>span{
      right:0;
      display:flex;
      flex-wrap:wrap;
      justify-content:flex-end;
      align-items:center;
      float:right;
    }
  }
  @media only screen and (max-width: 1160px) {
    height:150px;
    span{
      justify-content:center;
      align-items:center;
      button{
        margin-bottom:10px;
      }
    }
  }
  
`

const AccordionForm=styled.div`
    width:107%!important;
    div{
      transition:all .51s ease-in-out;
    }
    display:flex;
    flex-wrap:wrap;
    p{
      display:flex;
      flex-wrap:wrap;
    }
    position:relative;
    padding-bottom:40px;
    padding-top:20px;
    .form-bt-button{
      background-color:#487eb0;
      opacity:0.5;
      transition:all 0.9s ease-in-out;
      color:#fff;
      position:absolute;
      right:calc(8% - 20px);
      bottom:0;
      &:hover{
        background-color:#00a8ff;
        opacity:1;
        span.text::before{
          content:'';
        }
      }
      svg{
        margin:5px;
      }
      span.text{
        &::before{
          content:'';
        }

      }
    }
    
    .clear-filter{
      background-color:#812347;
      opacity:0.5;
      transition:all 0.9s ease-in-out;
      color:#fff;
      position:absolute;
      right:calc(8% - 20px);
      bottom:0;
      &:hover{
        background-color:#812377;
        opacity:1;
        span.text::before{
          content:'Filtreyi Temizle';
        }
      }
      svg{
        margin:5px;
      }
      span.text{
        &::before{
          content:'';
        }

      }

    }
    
`

const ShortcutContent=styled.div`
  .shortcut-content{
    &:focus-within{
    // background:url(${searchWall});

    }
    background-color:#fff;
    width:100%;
    border-radius:20px;
    margin-bottom:20px;
    .shortcut-items{
      display:flex;
      flex-wrap:wrap;
      justify-content:center;
      align-items:center;
      padding:0px 20px;
      height:100%;
      position:relative;
      ul{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        background-color:#eee;  
        padding:20px 0px;
        margin:5px;
        border-radius:20px;
        li{
          position:relative;
          min-height:40px;
          span{
            right:-25px;
            top:-20px;
          }
          cursor:pointer;
          svg{
            margin-right:5px;
          }
          h2{
            font-size:15px;
            
          }
        }
      }
      .item-box{
        span{
          font-size:50px;
        }
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        height:200px;
        width:200px;


      }
    }
    .head{
      display:flex;
      justify-content:center;
      align-items:center;
      position:relative;
      
      head:70px;
      padding-top:20px;
      input{
        width:90%;
        height:50px;
        border:none;
        left:0;
        top:0;
        z-index:1;
        background-color:	rgba(241,231,230,.2);
        &:focus,&:hover{
          background-color:	rgba(241,231,230,.7);
        }
        padding-left:5px;
        border-radius:20px;
      }
      .icon{
        position:absolute;
        right:6%;
        svg{
          &:hover{
            cursor:pointer;
            color:#4c5733;
          }
        }
        z-index:2;
        top:30px;
      }
    }
  }

`

export {Content,ContentForm,ContentTitle,AccordionForm,ShortcutContent}