import React from 'react';
import { Button } from '@material-ui/core';
import Calculator from './Calculator';
export default function MainCalculator({open,setOpen}) {
  
  return (
    <>
    {
      open ? <Calculator close={setOpen} /> : <Button onClick={()=>setOpen(!open)}>Open Calculator</Button>
    }
    </>
  )
}
