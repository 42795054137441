import React,{useState} from 'react'
import NotifyItem from './NotifyItem'

export default function NotifyContent(props) {
  
  return (
    <div className='home-flex'>
      {
        props.data.map((item,index)=>{
          return (
            <NotifyItem  key={'notify-content-item'+index} icon={item.icon} name={item.name} items={item.items} />
          )
        })
      }
      </div>
  )
}
