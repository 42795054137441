import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowDown, faArrowLeft, faArrowRight, faHome} from '@fortawesome/free-solid-svg-icons'
import Fab from '@mui/material/Fab';
import { useHistory, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function DropDown(props) {

  const history=useHistory()

  const location=useLocation()
  const [open,setOpen]=useState(location.pathname.includes(props.path) ? true : false)
  const checkFirstChar=(items,text="")=>{
    let txt=text.toLowerCase().replace(/\s/g,'')
    
    if(items!=undefined ){
      

      let control=(txt.split("").filter((char,index)=>{
        if(items.label.split(" ").length==txt.length){
          if(items.label.split(" ")[index].charAt(0).toLowerCase()==char){
            return true
          }
        
        }
        
        
      }));
      if(control.length==txt.length){
        return true
      }




    }
    return false
  }
  useEffect(() => {
    if(props.search!=''){
      let check=0
      props.items.map((e)=>{
        if(e.label){
          if(
            e.label.toLowerCase().includes(props.search.toLowerCase()) ||
            e.path.toLowerCase().includes(props.search.toLowerCase()) 
          ){
            check++
          }else{
            if(checkFirstChar(e,props.search)){
              check++
            }
          }
        }
        
      })
      if(check>0){
        setOpen(true)
      }
    }
  }, [props.search])
  
  return (
    <>
      <Fab variant='extended'  onClick={()=>{setOpen(!open);props.mainOnClick()}}>
        <div  > <span>{props.icon}</span> {props.label}
        
        <FontAwesomeIcon style={{transform:`rotate(${open ? 180 : 0}deg)`,transition:'500ms all'}} icon={faArrowDown}  />
      </div>
      </Fab>
      <div style={{
        height:open ? 'auto' : 0,
        transform:open ? 'translateX(0) scale(1)' : 'translateX(-70%) scale(0)' ,
        visibility:open? 'visible' : 'hidden',
        transition:'600ms all',
        margin:0
      }}>
        {
          (
            props.search=='' ? props.items :
            props.items.filter((e)=>{
              if(e.label){
                if(
                  e.label.toLowerCase().includes(props.search.toLowerCase()) ||
                  e.path.toLowerCase().includes(props.search.toLowerCase()) 
                ){
                  
                  return e
                }else{

                  if(checkFirstChar(e,props.search)){
                    
                    return e
                  }

                }
              }
              return false
            })
          ).map((item,index)=>{
            return(
              
                <NavLink onClick={()=>props.onClick()} exact to={props.path+item.path} key={item.label+index}>
                  <Fab variant="extended" className='subitem' onClick={()=>history.push(props.path+item.path)} >
                  <span><FontAwesomeIcon icon={faArrowRight}/></span> {item.label}
                  </Fab>  
                </NavLink>
            )
          })
        }
      </div>
    </>
  )
}
