import React from 'react'
import { faBan, faLock, faLockOpen, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import RouterPrompt from '../Content/RouterPrompt'
import { useSoundEffect } from '../Content/Sounds';
export default function EditButton(props) {
  const {lockPlay} = useSoundEffect()
  return (
    <>
    {
      props.edit ?
      <RouterPrompt
      when={props.edit}
      message={'Düzenleme işlemini onaylıyor musunuz?'}
      title={<>TEST</>}
      />
      :
      null
    }
    
    {
      props.cancel!=undefined && props.edit ?
      <Button
      onClick={()=>{
        props.cancel();
        toast('Düzenleme işlemi iptal edildi.');
        lockPlay();
      }}
      variant="contained" color="primary" style={{marginRight:20,backgroundColor:props.edit ? '#f50057' : '',color:props.edit ? '#effe' : '#000'}}>
        <FontAwesomeIcon icon={faBan} />
        İptal
      </Button>
      : null
    }
    {
      props.extras!=undefined && props.edit ?
      props.extras.map((item,index)=>{
        return (
          <Button key={'extra-btns-'+index} onClick={item.onClick} style={item.styles} variant='contained'>{item.icon} {item.text}</Button>
        )
      })
      :null
    }
    
    <Button 
      onClick={(e)=>{
        props.clicker(e);
        if(!props.edit){
          lockPlay();
        }
      }}
      variant="contained" color="primary"  style={{backgroundColor:!props.edit ? '#f50057' : '',color:!props.edit ? '#effe' : '#000'}}>
      
      <FontAwesomeIcon icon={props.edit ? faSave : faLock} />
      {props.edit ? 'Kaydet' : 'Düzenle'}

    </Button> 
    </>
  )
}
