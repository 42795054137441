import React,{useState,useEffect} from 'react'
import {HeaderContainer} from '../../styles/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,  faUser,  faChevronDown, faSignOutAlt, faCog, faCalculator } from '@fortawesome/free-solid-svg-icons';
import {useDispatch} from 'react-redux'
import { Menu } from '@headlessui/react'
import { Link, useHistory } from 'react-router-dom';
import { Fab } from '@mui/material';
import { changeSidebar } from '../stores/Site';
import { useSoundEffect } from '../Content/Sounds';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Button } from '@material-ui/core';
import Calculator from '../Calculator';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top:7,
    left:'calc((30% + 58px)*-1)',
  },
}));

export default function Header(props) {
  const dispatch = useDispatch()
  const history=useHistory()
  const [calculatorMode,setCalculatorMode]=useState(localStorage.getItem('calculatorMode')==='true' ? true : false)
  const {click,onSlide} = useSoundEffect();
  const clickEd=()=>{
    click()
  }
  useEffect(() => {
    localStorage.setItem('calculatorMode',calculatorMode)
  }, [calculatorMode]);
  
  return (
    <HeaderContainer isOpen={props.isOpen}> 
      <div className='bars' onClick={()=>{
        dispatch(changeSidebar());
          onSlide()
      }}>
        <Fab variant="extended">
          <FontAwesomeIcon  icon={faBars} />
        </Fab>
      </div>
      <div className='headerItems'>
        
        <div className='headerItem'>

          <Stack spacing={125} style={{marginRight:30}} direction="row">
          
            <StyledBadge anchorOrigin={{vertical: 'top',horizontal: 'left',}} badgeContent={localStorage.getItem('group')} color="secondary">
              <span>{localStorage.getItem('titlename')}</span>
            </StyledBadge>
            
          </Stack>

        </div>
        <div className='headerItem'>
          
          {
            calculatorMode ?
            <Calculator open={calculatorMode} setOpen={setCalculatorMode} />
            :
            <Button onClick={()=>setCalculatorMode(true)}>
              <FontAwesomeIcon icon={faCalculator} />
            </Button>
          }
          

        </div>
        <div className='headerItem'>
          <Menu > 
              
              <Menu.Button  className={'dropBtn'}><FontAwesomeIcon onClick={clickEd} icon={faUser} /><span><FontAwesomeIcon onClick={clickEd} icon={faChevronDown} /></span></Menu.Button>
              <Menu.Items className={'sub'}>
                <Menu.Item onClick={()=>clickEd()}>
                    <Link to="/ayarlar" ><FontAwesomeIcon icon={faCog} /> Ayarlar</Link>
                </Menu.Item>
                <Menu.Item onClick={()=>clickEd()}>
                    <Link to="/cikis-yap"><FontAwesomeIcon icon={faSignOutAlt} /> Çıkış Yap</Link>
                </Menu.Item>
              </Menu.Items>
            </Menu>
        </div>
      </div>
    </HeaderContainer>
  )
}
