import styled from "styled-components";

const CopyrightContainer=styled.div`
  position:absolute;
  bottom:0;
  left:50%;
  transform:translateX(-50%);
  font-size:12px;
  color:#777;
  a{
   
    margin:0 5px;
    text-decoration:none;
    color:#555;
  }
`

export {CopyrightContainer}