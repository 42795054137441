import React,{useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import DropDown from './DropDown'
import {SidebarContainer} from '../../styles/Menu'
import logo from '../../images/asgrouplogo2.png'
import Fab from '@mui/material/Fab';
import { useHistory } from 'react-router-dom'
import {Routes} from '../../Routes'
import { useSoundEffect } from '../Content/Sounds';
export default function Sidebar(props) {

  const {click,click2} = useSoundEffect();
  const history=useHistory()
  const [search,setSearch]=useState(localStorage.getItem('sideBarsearch') ? localStorage.getItem('sideBarsearch') : '')
  useEffect(() => {
    localStorage.setItem('sideBarsearch',search)
  }, [search])
  const checkFirstChar=(items,text)=>{
    let txt=text.toLowerCase().replace(/\s/g,'')
      
    if(items.props.items){
      let check=items.props.items.filter(item=>{


        let control=txt.split("").filter((char,index)=>{
          
          let subControl=(item.label.split(" ")).filter((char2,index2)=>{

            if(txt.charAt(index2)===char2.charAt(0).toLowerCase()){
              return true
            }

          })

          if(subControl.length==txt.length){
            
            return true
          }

        })

        if(control.length==txt.length){
           
          return true
        }


      })
      if(check.length>0){
        return true
      }
    }
  }
  const getFilteredRoutes=()=>{
    return Routes.filter((e)=>{
      if(e!=undefined){
        if(e.label!=undefined && e.label!=null && e.label!="Ayarlar"){
          if(
            e.label.toLowerCase().includes(search.toLowerCase()) ||
            e.path.toLowerCase().includes(search.toLowerCase()) 
          ){
            return e
          }else if(e.props.items){
            if(e.props.items.filter((b)=>{
              if(b.label){
                if(b.label.toLowerCase().includes(search.toLowerCase()) || b.path.toLowerCase().includes(search.toLowerCase())){
                  return b
                }
              }
            }).length>0){
              return e
            }
          }
          
          if(checkFirstChar(e,search)){
            return e
          }


        }
      }
      
      return false
    })
            
  }
  return (
    <SidebarContainer isOpen={props.isOpen}>
      <div className='logo'>
        <NavLink to="/"><img src={logo} /></NavLink>
      </div>

      

      <div className='navigation'>

          <div>

            <input type="text" placeholder='Ara' value={search} onChange={(e)=>setSearch(e.target.value)} />

          </div>
          
          {
             getFilteredRoutes().map((item,index)=>{
              if(item!=undefined){
                if(item.props!=undefined && item.props.items && item.props.items.length>0){
                  return(
                    <DropDown mainOnClick={()=>click2()}  onClick={()=>{click() }} search={search} key={item.label+index} path={item.path} icon={item.icon} label={item.label} items={item.props.items} />
                  )
                }else if (item.label && item.icon) {
                  return(
                    <NavLink  onClick={()=>{ click() }} exact to={item.path} key={item.label+index}>
                      <Fab variant="extended" className='item' onClick={()=>history.push(item.path)} >
                      <span>{item.icon}</span> {item.label}
                      </Fab>  
                    </NavLink>
                  )
                }
              }
              
            })
              
          }
      </div>
      
      <div className='sideHr'/>

      <footer className='footer'>
        <a href="https://www.dehasoft.com.tr">Dehasoft </a> &copy;   {new Date().getFullYear()}
      </footer>

    </SidebarContainer>
  )
}
