import React,{useEffect} from 'react'
import Switch from "react-switch";
import { useSoundEffect } from '../Content/Sounds';
import { toast } from 'react-toastify';
import axios from 'axios'
export default function GroupSwitchItem(props) {
  const {data,perms,setPerms,edit}=props

  const {lampOn,lampOff} = useSoundEffect();
  const [checked, setChecked] = React.useState(data.value ? data.value : false);
  useEffect(() => {
    
    if(props.groupId==0 || checked==data.value){
      if(checked){
        setPerms([...perms,(data.id ? data.id : data.permID)])
      }else{
        setPerms(perms.filter(item=>{
          return item!==(data.id ? data.id :data.permID)
        }))
      }
    }
    
  }, [checked])

  const changeGroupPerm= async (e)=>{
    if(props.groupId!=0){
      
      if(!checked){
        axios({
          method:'post',
          data:{
            groupID:props.groupId*1,
            permID:data.permID
          },
          url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_ADD_GROUP_PERM,
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          }
        })
        .then(response=>{
          toast(response.data.message,{type:'success'})
          lampOn()
          setChecked(true)
        })
        .catch(error=>{
          e.preventDefault()
        })
      }else{
        axios({
          method:'post',
          data:{
            groupID:props.groupId*1,
            permID:data.permID
          },
          url:process.env.REACT_APP_API_MAIN+((process.env.REACT_APP_API_DELETE_GROUP_SPESIFIC_PERM.replace('-groupId-',props.groupId).replace('-permissionId-',data.permID))),
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          }
        })
        .then(response=>{
          setChecked(false)
          lampOff()
          toast(response.data.message,{type:'success'})
        })
        .catch(error=>{
          e.preventDefault()
        })
      }
    }else{
      setChecked(!checked)
    }
  }

  return (
    <div className='input-group' style={{cursor:'pointer',width:'43%'}} >
      <span style={{cursor:'pointer'}}>{data.name ? data.name : data.permName}</span>
      <Switch disabled={!(edit ? edit : false)} checkedIcon={<></>} uncheckedIcon={<></>}  
      onChange={
        changeGroupPerm
      } checked={checked} />
    </div>
  )

}
