import React,{useState,useLayoutEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faArrowDown, faUserShield, faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom';
import EditButton from '../../components/Form/EditButton'
import axios from 'axios'
import {AccordionForm} from '../../styles/Content'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { Sentry } from 'react-activity'
import GroupSwitch from '../../components/Group/GroupSwitch'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet'
export default function GroupDetail(props) {  

  const {successPlay,errorPlay} = useSoundEffect()

  const buttonRef=useRef(null)
  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)
  const [selectedPerms,setSelectedPerms]=useState([])
  const history=useHistory()

  const [name,setName]=useState()
  const [detail,setDetail]=useState()
  const [date,setDate]=useState()
  const [perms,setPerms]=useState([])

  const [group,setGroup]=useState(null)
  let {id} = useParams()
  id=id==undefined ? props.id : id


  const getGroup=()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_USER_GROUP.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      setDetail(response.data.data.detail)
      setName(response.data.data.name)
      setDate(response.data.data.createDate)
      setEdit(false)
      setGroup(response.data.data)
      getGroupPerms()
    })
    
  }

  const getGroupPerms=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_GROUP_PERMISSIONS.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      setPerms(response.data.data.permissions)
    })
    .catch(res=>{
      toast.error(res.response.data.message)
      errorPlay()
    })
  }

  useLayoutEffect(() => {
    if(group===null){
      getGroup()
    }
  }, [group])

  const updateGroup=(e)=>{
    e.preventDefault()
    if(edit){
      if(name!=''){
        axios({
          method:'post',
          url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_UPDATE_USER_GROUP.replace('-id-',id)),
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          },
          data:{
            name:name==null ? '' : name,
            detail:detail==null ? '' : detail
          }
        })
        .then(response=>{
          successPlay()
          setEdit(false)
          toast.success(response.data.message)
        })
        .catch(error=>{
          toast.error(error.response.data.message)
          errorPlay()
          console.log(error.response)
        })
        
      }else{
        errorPlay()
        toast.error('Tüm alanları doldurmalısınız.')
      }
      
    }else{
      setEdit(true)
    }
    
  }

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  
  const confirmDelete=async()=>{
    let set=await deleteGroup(true)
  }
  const deleteGroup=async(confirmedDelete=false)=>{
    const seter=await setEdit(false)
    if(deleteConfirm==false && confirmedDelete!=true ){
      setDeleteDialog(true)
      return
    }else{
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'post',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_USER_GROUP.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      successPlay()
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      history.push('/grup/liste')
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
  const getDetailContent=()=>{
    return(
      <form onSubmit={updateGroup} >
          <Title text={<><FontAwesomeIcon icon={faUserShield} /> Grup Kartı</>} icon={
          <>
          <EditButton extras={[{text:'Grubu Sil',styles:{marginRight:20,backgroundColor:'#ff7979',color:'#fff'},icon:<FontAwesomeIcon icon={faTimesCircle} />,onClick:deleteGroup}]} cancel={getGroup}  edit={edit} clicker={updateGroup} />
          </>} />
          <div style={{width:'105%'}}>
            <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara"
            inputClass='search-input' 
            rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } />
          </div>

          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Grup Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                  <div className='input-group' style={{width:'44%'}} >
                    <span>Grup Adı :</span>
                    <FormInput value={name} setValue={setName} mainStyle={{width:'43%'}} disabled={!edit} nomargin placeholder="Adı"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}}>
                    <span>Açıklaması :</span>
                    <FormInput value={detail}  setValue={setDetail} nomargin mainStyle={{width:'43%'}}  disabled={!edit} placeholder="Açıklaması"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}}>
                    <span>Oluşturma Tarihi :</span>
                    <FormInput value={date}  disabled nomargin mainStyle={{width:'43%'}} placeholder="Kayıt Tarihi"  type='text'  />
                  </div>
                {
                  edit ? 
                  <div className='addButton' style={{right:'10%',bottom:5}}>
                    <Button ref={buttonRef} type='submit' color="primary" variant="contained">Kaydet</Button> 
                  </div>
                  
                  : null
                }
              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Grup Yetkileri <span style={{fontSize:12,fontWeight:400,marginLeft:30}}>( Kaydetmeden anlık güncelleme yapılır! )</span></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                  
                  {
                    perms.length>0 ?
                    <GroupSwitch data={perms} edit={edit} groupId={id} perms={selectedPerms} setPerms={setSelectedPerms} /> : 
                    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',height:100}}>
                      <Sentry size={30} color={'#000'} />
                    </div>
                  }

              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          
          </form>
    )
  }
  return (
    <>
      {
        props.id ? 
        <></>
        :
        <Menu/>
      }
      <Helmet>
        <title>Grup Kartı</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Kullanıcı Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />
      {
        props.id ? 
        <>
        {
          getDetailContent()
        }
        </>
        :
        <Content sidebar={sidebar} style={{marginBottom:props.id ? -350 : null}}>
        
          <ContentForm>
            
            {
              getDetailContent()
            }

          </ContentForm>

        </Content>
      }
    </>
  )
}
