import React,{useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {useSelector} from 'react-redux'
import {Content,ContentForm} from '../../styles/Content'
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom'
import UserDetail from './UserDetail';
export default function UserCard(props) {
  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)
  
  const [value, setValue] = React.useState(0);
  const [ids,setIds]=React.useState(props.location.state.ids)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(ids.length<1){
      history.push('/kullanici/liste')
    }
  }, [ids])
  return (
    <>
      <Menu />
      <Content sidebar={sidebar} >
        <ContentForm>
      
            <div className='tab-btns'>
              {
                  ids.map((item,index)=>{
                    return(
                      <Button key={'tab-btn-item-'+index} className={`tab-btn ${value==index ? 'active' : null}`}>
                      <div style={{paddingRight:25}} onClick={()=>setValue(index)} >{item.name}</div>
                      <span className='closer' onClick={()=>{
                        setIds(ids.filter(initem=>initem.id!==item.id))
                        if(value==index){
                          setValue(0)
                        }
                      }}>
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      </Button>
                    )
                  })
                }
            </div>
            {
              ids.map((item,index)=>{
                return(
                  <div key={'id-div-item-'+index} style={{display:value==index ? 'block' : 'none'}}>
                    <UserDetail  id={item.id} />
                  </div>
                )
              })
            }
          {/* {
            props.location.state.ids.map(item=>{
              return(
                <GroupDetail id={item} />
              )
            })
          } */}
        </ContentForm>
      </Content>
    </>
  )
}
