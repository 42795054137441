import React from 'react'
import { FormElement } from '../../styles/Form'
import GroupSwitchItem from './GroupSwitchItem'

export default function GroupSwitch(props) {

  return (
    <>
    {
      props.data.map((item,index)=>{
        return(
          <GroupSwitchItem key={`group-switch-${index}`}  groupId={props.groupId ? props.groupId : 0} edit={(props.edit!=undefined ? props.edit : true)} data={item} perms={props.perms} setPerms={props.setPerms} />
        )
      })
    }
    </>
  )
}
