import { createSlice } from "@reduxjs/toolkit";

import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const popup = createSlice({
  name:'popup',
  initialState:{
    popupOpen:localStorage.getItem('popupOpen')==='true' ? true : false,
    popupContent:localStorage.getItem('popupContent') ? localStorage.getItem('popupContent') : null,
    ref:null,
    dialog:null
  },
  reducers:{
    closeDialog: (state, action) => {
      state.dialog = null
      alert(123123)
    },
    setDialog: (state, action) => {
      if(action.payload==null){
        state.dialog = null
        return
      }
      state.dialog = <>
        <Dialog
            open={action.payload.open}
            onClose={action.payload.close}
          >
          <DialogTitle>
            {action.payload.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {action.payload.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={action.payload.close}>İptal Et</Button>
            <Button variant="contained" color="primary" onClick={()=>action.payload.confirm(action.payload.id)} autoFocus>
              Onayla
            </Button>
          </DialogActions>
        </Dialog>
      </>

    },
    
    changePopup:(state,action)=>{
      state.popupOpen=!state.popupOpen
      state.popupContent=action.payload
      localStorage.setItem('popupOpen',state.popupOpen)
      localStorage.setItem('popupContent',state.popupContent)
    },
    changeRef:(state,action)=>{
      state.ref=action.payload
    },
    dialogPopup:(state,action)=>{
      state.popupOpen=true
      state.popupContent=action.payload
    },
  }
})

export const {changePopup,changeRef,setDialog,closeDialog} = popup.actions
export default popup.reducer