
import React from 'react'
import {Routes} from '../../../Routes'
import Box from './Box'
export default function Items({search}) {

  const checkFirstChar=(items,text)=>{
    let txt=text.toLowerCase().replace(/\s/g,'')
    
    if(items!=undefined ){
      
      let control=(txt.split("").filter((char,index)=>{
        if(items.label.split(" ").length==txt.length){
          if(items.label.split(" ")[index].charAt(0).toLowerCase()==char){
            return true
          }
        
        }
        
      }));
      if(control.length==txt.length){
        return true
      }
    }
    return false
  }
  const getFilteredRoutes=()=>{
    
    return Routes.filter((e)=>{

      if(e!=undefined){

        if(e.label!=undefined && e.label!=null && e.label!="Ana Sayfa"){

          if(

            e.label.toLowerCase().includes(search.toLowerCase()) ||
            e.path.toLowerCase().includes(search.toLowerCase()) 
          ){
            return e
          }else if(e.props.items){
            if(e.props.items.filter((b)=>{
              if(b.label){
                if(b.label.toLowerCase().includes(search.toLowerCase()) || b.path.toLowerCase().includes(search.toLowerCase())){
                  return b
                }
              }
            }).length>0){
              return e
            }
          }
          
          if(checkFirstChar(e,search)){
            return e
          }


        }
      }
      
      return false
    })
            
  }
  
  return (
    <>
      {getFilteredRoutes().map((item,index)=>{
        if(item!=undefined && item.label!=undefined && item.icon!=undefined  ){
          return (
          <React.Fragment key={`item-box-${index}`}>
            <Box item={item}/>
          </React.Fragment>
          )
        }
        
      }
      )}
    </>
  )
}
