import React,{useState,useEffect} from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { useSelector } from 'react-redux'
export default function Menu() {
  const [open,setOpen]=useState(localStorage.getItem('sidebar')==='true' ? true : false)
  
  const sidebar=useSelector(state=>state.site.sidebar)

  useEffect(() => {
    localStorage.setItem('sidebar',open)
  }, [open])

  return (
    <>
      <Header isOpen={sidebar} />
      <Sidebar isOpen={sidebar}/>
    </>
  )
}
