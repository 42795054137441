const LightTheme={

    body: '#ffffff',
    text: '#000',
    header:'rgba(236,240,241,.7)',
    headerText:'#000',
    spesificOpacity:.7,
    bars:'#F2DA92',
    errorBackground:'#f44336',
    errorText:'#fff',
    errorPageBackground:'#f44355',
    content:'#eee',
    acordion:'#fff',
    shadow:'rgba(0,0,0,0.75)',
    popup:'rgba(0,0,0,0.5)',
    primary:'#00a8ff',
}
const DarkTheme={
    body: '#363537',
    text: '#FAFAFA',
    header:'#555',
    headerText:'#fff',
    spesificOpacity:.4,
    bars:'#494539',
    errorBackground:'#f44336',
    errorText:'#fff',
    errorPageBackground:'#f44355',
    content:'#666',
    acordion:'#777',
    shadow:'rgba(0,0,0,0.75)',
    popup:'rgba(0,0,0,0.5)',
    primary:'#0097e6',
}

export {LightTheme,DarkTheme}