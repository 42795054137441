import React from 'react'
import { Link } from 'react-router-dom'

import {CopyrightContainer} from '../../styles/Main'

export default function index() {
  return (
    <CopyrightContainer>
      Copyright &copy;  {new Date().getFullYear()} <a href={'https://dehasoft.com.tr'} >Dehasoft</a>
    </CopyrightContainer>
  )
}
