import styled from "styled-components";
import yellowRepeater from '../images/white-repeater.jpg'
const SupportContainer=styled.div`
  &>button{
    transition:all 0.5s ease;
    position:fixed;
    box-shadow:0!important;
    border-radius:50%!important;
    background:transparent!important;
    bottom:15px;
    right:15px;
    z-index:20;
    height:50px;
    width:50px;
    img{
      width:50px;
      height:50px;
      -webkit-filter: drop-shadow(0px 0px 5px #222 );
      filter: drop-shadow(0px 0px 5px #222);
      cursor:pointer;
    }
    @media only screen and (max-width:700px){
      display:none;
    }
  }
  .support-box{
    position:fixed;
    transition:all 0.5s ease;
    @media only screen and (max-width:700px){
      display:none;
    }
    
    bottom:5vh;
    right:15px;
    box-shadow:0px 0px 36px 8px ${({theme})=>theme.shadow};
    background-color:${({theme})=>theme.body};
    width:45vw;
    padding:10px 5px;
    height:45vh;
    border-radius:10px;
    .chat-screen{
      display:flex;
      height:100%;
      position:relative;
      .chat{
        position:absolute;
        top:0;
        right:0;
        width:35vw;
        background-color:${({theme})=>theme.header};
        height:45vh;
        display:flex;
        flex-direction:column;
        .user-title{
          flex:1;
          display:flex;
          justify-content:center;
          background-color:${({theme})=>theme.header};
          align-items:center;
        }
        .chat-box{
          flex:12;
          max-height:calc(100%);
          overflow-y:scroll;
          -ms-overflow-style: none;  
          scrollbar-width: none; 
          &::-webkit-scrollbar{
            display: none;
          }
          position:relative;
          width:100%;
          .chat-item{
            width:80%;
            margin:5px;
            margin-left:3%;
            margin-bottom:15px;
            padding:10px;
            color:#fff;
            border-radius:10px;
            border-top-left-radius:0;
            font-size:14px;
            background-color:${({theme})=>theme.primary};
          }
          .chat-item.other{
            background-color:${({theme})=>theme.body};
            color:${({theme})=>theme.text};
            margin-left:13%;
            border-top-right-radius:0;
            border-top-left-radius:10px;
          }
        }
        .empty,.null-message{
          flex:15;
          display:flex;
          justify-content:center;
          align-items:center;
          flex-direction:column;
          font-style:italic;
          font-weight:bold;
        }
        .null-message{
          margin-top:40px;
        }
        .send-box{
          flex:1;
        }
      }
      .user-list{
        position:absolute;
        left:0;
        width:10vw;
        height:100%;
        button{
          width:100%;
          min-height:45px;
        }
        .user-list-header{
          height:50px;
          display:flex;
          justify-content:center;
          align-items:center;
          
        }
      }

    }
  
    z-index:350;
    &>div{
      
      position:relative;
      .close{
        button{
          padding:0!important;
          background:#e74c3c;
          border-radius:50%;
          height:30px;
          min-width:30px;
          font-size:18px;
          max-width:30px;
          color:white;
          z-index:355;
          width:30px;
        }
        position:absolute;
        right:-20px;
        top:-20px;
      }
    }
  }
`

export {SupportContainer}