import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import NavigationPrompt from "react-router-navigation-prompt";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function RouterPrompt(props) {
  return (
    <NavigationPrompt
      // Children will be rendered even if props.when is falsey and isActive is false:
      renderIfNotActive={true}
      // Confirm navigation if going to a path that does not start with current path:
      when={(crntLocation, nextLocation, _action) =>
        !nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname) 
      }
    >
      {({ isActive, onCancel, onConfirm }) => {
        if (isActive) {
          return (
            <Dialog
              open={props.when}
              onClose={onCancel}
            >
            <DialogTitle>
              <FontAwesomeIcon icon={faExclamationCircle} /> {"Sayfadan ayrılmak üzeresiniz!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Yaptığınız değişiklikler kaybolabilir. Sayfadan ayrılmak istediğinize emin misiniz?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="secondary" onClick={onCancel}>İptal</Button>
              <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>
                Onayla
              </Button>
            </DialogActions>
          </Dialog>
          );
        }
        return null;
      }}
    </NavigationPrompt>
  )
}

