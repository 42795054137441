import React,{useState,useEffect,createRef} from 'react'
import Menu from '../components/Navigation/Menu'
import {Content,ContentForm} from '../styles/Content'
import {useSelector,useDispatch} from 'react-redux'
import Title from '../components/Content/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowDown,  faMoon, faSun, faVolumeUp, faVolumeMute, faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Support from '../components/Support'
import {AccordionForm} from '../styles/Content'
import FormInput from '../components/Form/Input'
import Switch from "react-switch";
import {changeTheme,changeVolume} from '../components/stores/Site'
import { Button } from '@material-ui/core';
import {changeBackground} from '../components/stores/Site'
import { useSoundEffect } from '../components/Content/Sounds';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet'

export default function Home(props) {  
  const ref = createRef(null);

  const {sidebar}=useSelector(state=>state.site)

  const [image,setImage]=useState(null)
  const createImage=(file)=>{
    try {
      let reader = new FileReader();
      let img="";
      reader.onload = (e) => {
        if(e.target.result!=null){
          setImage(e.target.result)
        }else{
        }
      };
      reader.readAsDataURL(file);
    } catch (er) {
    }

  }
  const onFileChange=(e)=>{
    setImage(e.target.result)

    let files=e.target.files || e.dataTransfer.files

    createImage(files[0])
  }
  useEffect(() => {
    if(image!=null){
      dispatch(changeBackground(image))
    }
  }, [image])

  const {theme,volume} = useSelector(state => state.site)
  const {lampOn,lampOff}=useSoundEffect()
  const dispatch = useDispatch() 

  return (
    <React.Fragment>
      <Menu/>
      <Helmet>
        <title>Ayarlar</title>
      </Helmet>
      <Content ref={ref} sidebar={sidebar}  >
        <Support refer={ref} />
        <ContentForm>
          
          <form onSubmit={(e)=>{e.preventDefault()}}>

          <Title text={<><FontAwesomeIcon icon={faCog} /> Ayarlar</>} />

          <Accordion
          className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Tema Ayarları</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>

                <div className='input-group' style={{width:'43%'}}>
                  <span>Renk Teması :</span>
                  <Switch 
                    height={20} handleDiameter={28} width={50} onColor={'#beb84a'} 
                    offHandleColor="#000"
                    onHandleColor="#fff"

                    checkedHandleIcon={<div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}><FontAwesomeIcon icon={faMoon} /></div>} 
                    uncheckedHandleIcon={<div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}><FontAwesomeIcon color={'#fff300'} icon={faSun} /></div>} 
                    activeBoxShadow="0px 0px 1px 2px #fffc35" 
                    checkedIcon={<div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}><FontAwesomeIcon color={'#fff300'} style={{fontSize:15}} icon={faSun} /></div>} 
                    uncheckedIcon={<div style={{display:'flex',height:'100%',justifyContent:'center',alignItems:'center'}}><FontAwesomeIcon  style={{fontSize:15}} icon={faMoon} /></div>} 
                    checked={theme=='LightTheme' ? true : false} 
                    onChange={()=>{
                      dispatch(changeTheme());
                        if(theme=='LightTheme'){
                          lampOn()
                        }else{
                          lampOff()
                        }
                      
                    }} />
                </div>

                <div className='input-group' style={{width:'43%'}}>
                  <span>Ses Efektleri :</span>
                  <Box sx={{ width: 300 }}>
                  <Stack  spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Button 
                    onClick={()=>{
                      if(volume>0.1){
                        dispatch(changeVolume(volume-0.1))
                        lampOn()
                      }
                    }}
                    style={{maxWidth:'5px'}}>
                      <FontAwesomeIcon icon={faVolumeMute}/>
                    </Button>
                    <Slider onMouseUp={()=>lampOn()} aria-label="Volume"  min={0} max={1} step={0.1} onChange={(e)=>{
                    dispatch(changeVolume(e.target.value))
                  } } value={volume}  />
                    <Button style={{maxWidth:'5px'}}
                    onClick={()=>{
                      if(volume<1){
                        dispatch(changeVolume(volume+0.1))
                        lampOn()
                      }
                    }}
                    
                    >
                    <FontAwesomeIcon icon={faVolumeUp}/>
                    </Button>
                  </Stack>
                  </Box>
                </div>

                <div className='input-group' style={{width:'43%'}} >
                    <>
                    <span>Arka Plan Görseli :</span>
                    <img height={70} src={localStorage.getItem('background') ? localStorage.getItem('background') : image} className='closer' />
                    <FormInput nomargin  onChange={onFileChange} type='file' rightIcon={<FontAwesomeIcon cursor={'pointer'} onClick={()=>{dispatch(changeBackground('0'));setImage(null)}} icon={faTimes}/>} />
                    </>
                </div> 

                
              </AccordionForm>

            </AccordionDetails>
          </Accordion>


          </form>

        </ContentForm>

      </Content>
    </React.Fragment>
  )
}
