import React,{useState,useEffect,createRef,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector,useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faExclamationTriangle, faList, faPlusCircle, faSdCard, faTimes, faTrashAlt, faTimesCircle, faFileExport } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import ThreeDot from '../../components/Form/ThreeDot'
import { useHistory } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {AccordionForm} from '../../styles/Content'
import Support from '../../components/Support'
import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import ExcelButton from '../../components/Tables/Excel'
import PdfButton from '../../components/Tables/Pdf'
import { Helmet } from 'react-helmet'

export default function GroupList() {  

  const dispatch=useDispatch()

  const [loading,setLoading]=useState(true)

  const {errorPlay,successPlay}= useSoundEffect()

  const [selectedRows,setSelectedRows]=useState([])

  const [search,setSearch]=useState('')
  const [group,setGroup]=useState('')
  const [user,setUser]=useState('')
  const [date,setDate]=useState('')
  const [asid,setAsid]=useState('')

  const [data,setData]=useState([])
  useEffect(()=>{
    if(data.length===0){
      getData()
    }
  },[data])

  const getData=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_USER_GROUPS,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      setData(response.data.data)
      setLoading(false)
    })
    .catch(res=>{
      setLoading(false)
    })
  }

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteId,setDeleteId]=useState(null)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [multiDeleteDialog,setMultiDeleteDialog]=useState(false)
  const [multiDeleteConfirm,setMultiDeleteConfirm]=useState(false)

  const confirmDelete=async()=>{
    let set=await deleteGroup(deleteId,true)
  }
  const confirmMultiDelete=async()=>{
    setMultiDeleteDialog(false)
    setMultiDeleteConfirm(false)
    const setin =await selectedRows.map( item=>{
      deleteGroup(item.id,true)
    })
    setSelectedRows([])
  } 
  const deleteGroup=(id,confirmed=false)=>{
    if((deleteConfirm==false) && confirmed==false ){
    
      setDeleteId(id)
      setDeleteDialog(true)
      
      return
    }else{
      setDeleteId(null)
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_USER_GROUP.replace('-id-',id)),
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response=>{

      getData()
      
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      successPlay()
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
 
  const ref = createRef(null);

  const {sidebar}=useSelector(state=>state.site)
  const {routes}=useSelector(state=>state.auth)
  const history=useHistory()
  
  const multiDelete=()=>{
    if(multiDeleteConfirm==false && multiDeleteDialog==false){
      setMultiDeleteDialog(true)
    }
  }
  const columns = [
      {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
      },
      {
          name: 'Grup Adı',
          selector: row => row.name,
          sortable: true,
      },
      {
          name: 'Oluşturulma Tarihi',
          selector: row => row.createDate,
          sortable: true,
      },
      {
          name: <ThreeDot items={
          <>
          <Link to={{
            pathname:'/grup/guncelle',
            state:{
              ids:selectedRows
            }
          }} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faSdCard} /> Grupların Kartı</Link>
          <a onClick={()=>multiDelete()} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faTrashAlt} /> Seçilenleri Sil</a>
          </>}  />,
          selector: row => 
          <ThreeDot items={
          <>
          <Link to={`/grup/guncelle/${row.id}`} ><FontAwesomeIcon icon={faSdCard} /> Grup Kartı</Link>
          <a onClick={()=>{deleteGroup(row.id)}}><FontAwesomeIcon icon={faTrashAlt} /> Sil</a>
          </>} id={row.serino} />,
          sortable: false,
          maxWidth: '3%',
      }
  ];

  const searchRef=useRef(null)

 
  const sortIcon = <FontAwesomeIcon style={{transition:'300ms all',fontWeight:'400'}}   icon={faArrowDown} />;
  
  const clearFilter=()=>{
    setSearch('')
    setUser('')
    setGroup('')
    setUser('')
    setAsid('')
    setDate('')
  }

  const dataSearchFilter=()=>{
    if(search==''  && group=='' && date==''  && asid==''){
      return data
    }else{
      return data.filter(item=>{
        let searchIndex=0
        let mainIndex=0
        if(search!=''){
          if(item.id.toString().indexOf(search)>-1){
            searchIndex=1
          }else if(item.name.toLowerCase().indexOf(search.toLowerCase())>-1){
            searchIndex=1
          }else if(item.createDate.toLowerCase().indexOf(search.toLowerCase())>-1){
            searchIndex=1
          }
        }else{
          searchIndex=1
        }
        

        if(searchIndex==1){

          if(group!='' ){
            if(item.name.toLowerCase().indexOf(group.toLowerCase())>-1){
              mainIndex+=1
            }
          }else{
            mainIndex+=1
          }
          if(date!='' ){
            if(item.createDate.toLowerCase().indexOf(date.toLowerCase())>-1){
              mainIndex+=1
            }
          }else{
            mainIndex+=1
          }
          if(asid!=''){
            if(item.id.toString().toLowerCase().indexOf(asid.toLowerCase())>-1){
              mainIndex+=1
            }
          }else{
            mainIndex+=1
          }


        }
        
        if(mainIndex==3 && searchIndex==1){
          return item
        }


      })
    }
  }

  return (
    <>
      <Menu/>
      <Helmet>
        <title>Grup Listesi</title>
      </Helmet>
      <Dialog
      open={deleteDialog}
      setOpen={setDeleteDialog}
      setConfirm={confirmDelete}
      selectedId={deleteId}
      content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
      title={<>Grup Tanımı Silme</>}
      icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Dialog
      open={multiDeleteDialog}
      setOpen={setMultiDeleteDialog}
      setConfirm={confirmMultiDelete}
      selectedId={deleteId}
      content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
      title={<>Toplu Grup Tanımı Silme</>}
      icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Content ref={ref} sidebar={sidebar}  >

        <Support refer={ref} />
        
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title text={<><FontAwesomeIcon icon={faList} /> Grup Listesi</>} icon={
          <>
          {
            selectedRows.length>0 ?
            <>
            <Button variant='contained' onClick={()=>{
              history.push({
            pathname:'/grup/guncelle',
            state:{
              ids:selectedRows
            }
          })
            }} style={{backgroundColor:'#9c88ff',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faSdCard} /> Grup Kartı</Button>
            <Button variant='contained' onClick={()=>multiDelete()} style={{backgroundColor:'#ff7979',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faTimesCircle} /> Grup Sil</Button>

            </>
            :
            <></>
          }
          {
            (routes.find(item=>item.permName=='Yeni Grup Oluştur')).value ? 
            <Button onClick={()=>history.push('/grup/ekle')} variant="contained" color="primary"  ><FontAwesomeIcon icon={faPlusCircle}/>Grup Oluştur</Button>
            :
            <></>
          }
          <ExcelButton columns={columns} data={dataSearchFilter()} />
          
          </>
          } />
          
          
          <Accordion
          className='accordion-form'
            style={{flexGrow:1,marginBottom:15}}
              defaultExpanded={false}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Arama Filtresi</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>
                  <Button onClick={()=>clearFilter()} variant="contained" className='clear-filter' ><FontAwesomeIcon icon={faTimes}/><span className='text'></span></Button>
                  <div className='input-group input-search'>
                    <FormInput 
                    value={search}
                    setValue={setSearch}
                    nomargin={true}
                    placeholder="Tüm alanlarda ara" 
                    refer={searchRef}
                    />
                  </div>

                  <div className='input-group' >
                    <span>ID</span>
                    <FormInput type="text" value={asid} setValue={setAsid}  placeholder="ID"  rightIcon={asid!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setAsid('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>Grup Adı</span>
                    <FormInput type="text"  value={group} setValue={setGroup}  placeholder="Grup Adı" rightIcon={group!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setGroup('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>Oluşturulma Tarihi</span>
                    <FormInput type="datetime-local" value={date} setValue={setDate}  placeholder="Grup Tanım Tarihi" rightIcon={date!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setDate('')}/> : null} />  
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          
          <div id="test">
          <DataTable
              
              pagination
              responsive
              paginationComponentOptions={{ noRowsPerPage: true }}
              sortIcon={sortIcon}
              defaultSortFieldId={1}
              onRowDoubleClicked={(e,rowData)=>{
                history.push(`/grup/guncelle/${e.id}`)
              }}
              highlightOnHover
              onclick
              selectableRows
              onSelectedRowsChange={(e)=>{
                setSelectedRows(e.selectedRows)
              }}
              pointerOnHover
              noDataComponent={<div style={{padding:20}}>{loading ? <Sentry size={34} />: <h1>Veri bulunamadı</h1>}</div>}
              columns={columns}
              data={
                dataSearchFilter()
              }
          />
          </div>
          
          <div className='addButton'>
            
            {/* <Button type='submit' color="primary" variant="contained">Ekipman Oluştur</Button> */}
          </div>

          </form>

        </ContentForm>

      </Content>
    </>
  )
}
