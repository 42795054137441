import {createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html,body{
    height:100%;
    width:100%;
    transition: all 0.5s ease;
    background:${(props) => props.background ? props.background : ({theme}) => theme.body};
    background-size: cover;
    &::before{
      content: '';
      height:100%!important;
      position: fixed!important;
      top: 0;
      left: 0;
      bottom:0;
      right:0;
      background: ${({theme})=>theme.body!='#ffffff' ? 'rgba(0,0,0,0.53)' : 'rgba(255,255,255,0.3)'};
    }
  }
  
  .hidden{
    position:fixed;
    margin-top:-4600vh;
    margin-left:-4600vw;
  }
  .chart{
    span{
      display:none!important;
    }
  }
  .loading{
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  body{
    overflow-x:hidden;
  }
  .rdt_TableRow:last-child{
    margin-bottom:160px!important;
  }
  .rdt_TableRow{
    background:${({theme})=>rgba(theme.body,0.5)}!important;
    color:${({theme})=>theme.text}!important;
    &:hover{
      background:${({theme})=>rgba(theme.text,0.33)}!important;
      color:${({theme})=>theme.body}!important;
      button{
        color:${({theme})=>theme.body};
      }
    }
    button{
      color:${({theme})=>theme.text};
    }
  }
  .rdt_TableBody{
    background:${({theme})=>rgba(theme.body,0.5)}!important;
  }
  .rdt_TableHeadRow{
    color:${({theme})=>theme.text};
    background-color:${({theme})=>rgba(theme.body,0.5)}!important;
    
  }
  .rdt_Pagination{
    background:${({theme})=>rgba(theme.body,0.5)};
    color:${({theme})=>theme.text};
    
  }
  .threeDot-btn{
    padding:10px;
    width:40px;
    cursor:pointer;
    height:40px;
    border-radius:100%!important;
    box-shadow:none!important;
    &:hover{
      background:${({theme})=>rgba(theme.text,0.33)}!important;
    }
  }
  .noselect {
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
  }
  .app-install-container{
    display:flex;
    justify-content:center;
    align-items:center;
    height:100vh;
    width:100vw;
    background:${({theme})=>rgba(theme.body,0.5)};
    position:fixed;
    top:0;
    left:0;
    z-index:9999;
    
  }
  .popup-container{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:${({theme}) => theme.popup};
    z-index:9999;
    display:flex;
    justify-content:center;
    align-items:center;
    .popup-content{
      position:relative;
      border-radius:4px;
      box-shadow:0px 0px 36px 8px ${({theme}) => theme.shadow};
      width:auto;
      height:auto;
      min-width:20%;
      max-width:90%;
      max-height:90%;
      min-height:20%;
      padding:20px;
      @media only screen and (max-width: 900px) {
        min-width:70%;
        min-height:50%;
      }
      background-color:${({theme}) => theme.body};
      .popup-close{
        position:absolute;
        top:10px;
        button{
          font-size:20px;
          width:10px;
          background-color:transparent;
          border-radius:0%;
          height:23px;
          transition:all 0.5s ease;
          &:hover{
            background-color:#ff6b6b;
            color:white;
          }
        }
        right:10px;
        z-index:9999;
        cursor:pointer;
      }
    }
  }
`
export {GlobalStyle}