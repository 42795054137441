import React,{useState,createRef,useEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'

import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faSdCard, faPlusCircle, faTimesCircle,faExclamationTriangle, faTimes, faList, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import ThreeDot from '../../components/Form/ThreeDot'
import { useHistory } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {AccordionForm} from '../../styles/Content'
import Support from '../../components/Support'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useSoundEffect } from '../../components/Content/Sounds';
import Dialog from '../../components/Content/Dialog'

export default function EquipmentList() {  
  const {routes}=useSelector(state=>state.auth)
  const {errorPlay,successPlay}= useSoundEffect()


  const [data,setData]=useState([])

  const [search,setSearch]=useState('')
  const [type,setType]=useState('')
  const [stockcode,setStockcode]=useState('')
  const [asmodel,setAsmodel]=useState('')
  const [asmarka,setAsmarka]=useState('')
  const [asid,setAsid]=useState('')
  const [selectedRows,setSelectedRows]=useState([])

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteId,setDeleteId]=useState(null)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [multiDeleteDialog,setMultiDeleteDialog]=useState(false)
  const [multiDeleteConfirm,setMultiDeleteConfirm]=useState(false)

  const getEquipments=()=>{
    axios({
      method: 'get',
      url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_EQUIPMENTS,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    })
    .then(res=>{
      setData(res.data.data.equipments)
    })
    .catch(err=>{
      console.log("er:",err);
    })
  }
  const multiDelete=()=>{
    if(multiDeleteConfirm==false && multiDeleteDialog==false){
      setMultiDeleteDialog(true)
    }
  }
  const confirmDelete=async()=>{
    let set=await deleteEquipment(deleteId,true)
  }
  const confirmMultiDelete=async()=>{
    setMultiDeleteDialog(false)
    setMultiDeleteConfirm(false)
    selectedRows.map( item=>{
      deleteEquipment(item.id,true)
    })
  } 
  const deleteEquipment=(id,confirmed=false)=>{
    if((deleteConfirm==false) && confirmed==false ){
    
      setDeleteId(id)
      setDeleteDialog(true)
      
      return
    }else{
      setDeleteId(null)
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_EQUIPMENT.replace('-id-',id)),
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response=>{

      getEquipments()
      
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      successPlay()
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
  

  useEffect(()=>{
    if(data.length<=0){
      getEquipments()
    }
  },[data])

  const ref = createRef(null);

  const {sidebar}=useSelector(state=>state.site)
  const history=useHistory()

  const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'AS Seri No',
        selector: row => row.asSeriNo,
        sortable: true,
    },
    {
        name: 'AS Model',
        selector: row => row.asModel,
        sortable: true,
    },
    {
        name: 'Türü',
        selector: row => row.typeName,
        sortable: true,
    },
    {
        name: <ThreeDot items={
        <>
        <Link to={{
          pathname:'/ekipman/guncelle',
          state:{
            ids:selectedRows
          }
        }} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faSdCard} /> Grupların Kartı</Link>
        <a onClick={()=>multiDelete()} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faTrashAlt} /> Seçilenleri Sil</a> 
        </>
        }  />,
        selector: row => 
        <ThreeDot items={<><Link to={`/ekipman/guncelle/${row.id}`} > Ekipman Kartı</Link></>}  />,
        sortable: false, 
        maxWidth: '3%',
    }
  ];

  const searchRef=useRef(null)


  const sortIcon = <FontAwesomeIcon style={{transition:'300ms all',fontWeight:'400'}}   icon={faArrowDown} />;

  const clearFilter=()=>{
    setSearch('')
    setType('')
    setStockcode('')
    setAsmodel('')
    setAsmarka('')
    setAsid('')
  }
  
 
  return (
    <React.Fragment >

      <Menu/>
      
      <Helmet>
        <title>Ekipman Listesi</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={deleteId}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Ekipman Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

        <Dialog
        open={multiDeleteDialog}
        setOpen={setMultiDeleteDialog}
        setConfirm={confirmMultiDelete}
        selectedId={deleteId}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Toplu Ekipman Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />
      <Content ref={ref} sidebar={sidebar}  >
        <Support refer={ref} />
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title  text={<><FontAwesomeIcon icon={faList} /> Ekipman Listesi</>} 
          icon={
          <>
          {
            selectedRows.length>0 ?
            <>
            <Button variant='contained' onClick={()=>{
              history.push({
            pathname:'/ekipman/guncelle',
            state:{
              ids:selectedRows
            }
          })
            }} style={{backgroundColor:'#9c88ff',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faSdCard} /> Ekipman Kartı</Button>
            <Button variant='contained' onClick={()=>multiDelete()} style={{backgroundColor:'#ff7979',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faTimesCircle} /> Ekipman Sil</Button>

            </>
            :
            <></>
          }
          {
            console.log(routes.find(item=>item.permName=='asd Oluştur'))
          }
          {
            
            routes.find(item=>item.permName=='Ekipman Oluştur')!=undefined ? 
            <Button onClick={()=>history.push('/ekipman/ekle')} variant="contained" color="primary"  ><FontAwesomeIcon icon={faPlusCircle}/> Ekipman Oluştur</Button>
            :
            <></>
          }
          </>
          }
          />
          
          
          <Accordion
          className='accordion-form'
            style={{flexGrow:1,marginBottom:15}}
              defaultExpanded={false}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Arama Filtresi</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>
                  <Button onClick={()=>clearFilter()} variant="contained" className='clear-filter' ><FontAwesomeIcon icon={faTimes}/><span className='text'></span></Button>
                  <div className='input-group input-search'>
                    <FormInput 
                    value={search}
                    setValue={setSearch}
                    nomargin={true}
                    placeholder="Tüm alanlarda ara" 
                    refer={searchRef}
                    />
                  </div>

                  {/* <div className='input-group' >
                    <span>AS Seri No</span>
                    <FormInput type="text" value={asid} setValue={setAsid}  placeholder="AS Seri No"  rightIcon={asid!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setAsid('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>Ekipman Türü</span>
                    <FormInput type="text"  value={type} setValue={setType}  placeholder="Ekipman Türü" rightIcon={type!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setType('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>Stok Kodu</span>
                    <FormInput type="text" value={stockcode} setValue={setStockcode}  placeholder="Stok Kodu" rightIcon={stockcode!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setStockcode('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>As Model</span>
                    <FormInput type="text" value={asmodel} setValue={setAsmodel}  placeholder="AS Model" rightIcon={asmodel!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setAsmodel('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span>As Marka</span>
                    <FormInput type="text" value={asmarka} setValue={setAsmarka}  placeholder="AS Marka"  rightIcon={asmarka!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setAsmarka('')}/> : null} />  
                  </div> */}

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          

          <DataTable
            pagination
            responsive
            paginationComponentOptions={{ noRowsPerPage: true }}
            sortIcon={sortIcon}
            defaultSortFieldId={1}
            selectableRows
            onSelectedRowsChange={(e)=>{
              setSelectedRows(e.selectedRows)
            }}
            onRowDoubleClicked={(e,rowData)=>{
              history.push(`/ekipman/guncelle/${e.id}`)
            }}
            highlightOnHover
            pointerOnHover
            noDataComponent={<div style={{padding:20}}>Veri bulunamadı!</div>}
            columns={columns}
            data={data}
        />
          
          
          <div className='addButton'>
            {/* <Button type='submit' color="primary" variant="contained">Ekipman Oluştur</Button> */}
          </div>

          </form>

        </ContentForm>

      </Content>
    </React.Fragment>
  )
}
