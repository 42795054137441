import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faUserPlus, faDolly, faPlus, faTimes, faSdCard, faList } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import ThreeDot from '../../components/Form/ThreeDot'
import { Link,useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Radio from '@mui/material/Radio';

import {useSoundEffect} from '../../components/Content/Sounds' 
import { Helmet } from 'react-helmet';
export default function ShipmentAdd(props) {

  const {successPlay,errorPlay}=useSoundEffect()
  

  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)
  
  const [loading,setLoading]=useState(false)
  
  const addUser=async(e)=>{
    e.preventDefault()
      //do something else
    
    // setLoading(true)
    // if(false){

    //   const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    //   const finallyGroup=groups.find(item=>item.name==selectedGroup)
    //   if(finallyGroup){
    //     axios({
    //       method:'post',
    //       url: process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_CREATE_USER,
    //       headers: {
    //         'Authorization': localStorage.getItem('token'),
    //         'Content-Type': 'application/json'
    //       },
    //       data:{
    //         name:name,
    //         userGroupID:finallyGroup.id,
    //         personelID:selectedUser
    //       }
    //     })
    //     .then(res=>{
    //       toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    //       successPlay()
    //       setName('')
    //       setSelectedGroup('')
    //       setSelectedUser(null)
    //     })
    //     .catch(error=>{
    //       toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    //       errorPlay()
    //     })
    //   }else{
    //     toast.update(loader, { render: 'Geçerli bir kullanıcı grubu seçmelisiniz', type: "error",isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    //     errorPlay()
    //     return
    //   }
    // }else{
    //   errorPlay()
    //   setLoading(false)
    //   toast.error('Tüm alanları doldurmalısınız')
    // }
  }
  
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Sevkiyat Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        <ContentForm>
          <form onSubmit={addUser}>
          <Title text={<><FontAwesomeIcon icon={faPlus} /><FontAwesomeIcon icon={faDolly} /> Yeni Sevkiyat Oluştur</>} />
          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Müşteri Bilgileri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>

                  <Button onClick={()=>alert()} variant="contained" className='form-bt-button' ><FontAwesomeIcon icon={faList}/><span className='text'>Cari Listesi</span></Button>
                
                  <div className='input-group' >
                    <span>Cari Kodu :</span>
                    <FormInput nomargin={true} placeholder="Cari Kodu"  type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Firma Adı :</span>
                    <FormInput nomargin={true} placeholder="Firma Adı" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>İlçe :</span>
                    <FormInput nomargin={true} placeholder="İlçe" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>İl :</span>
                    <FormInput nomargin={true} placeholder="İl" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Sevk Adres Tanımı :</span>
                    <FormInput nomargin={true} placeholder="Sevk Adres Tanımı" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Sevk Adres :</span>
                    <FormInput nomargin={true} placeholder="Sevk Ders" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Sevk İl :</span>
                    <FormInput nomargin={true} placeholder="Sevk İl" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Sevk İlçe :</span>
                    <FormInput nomargin={true} placeholder="Sevk İlçe" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Sevk Yetkili :</span>
                    <FormInput nomargin={true} placeholder="Sevk Yetkili" type='text' />
                  </div>

                  <div className='input-group' >
                    <span>Telefon :</span>
                    <FormInput nomargin={true} placeholder="Telefon" type='tel' />
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Sipariş Bilgileri</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <AccordionForm>
                <Button onClick={()=>alert()} variant="contained" className='form-bt-button' ><FontAwesomeIcon icon={faList}/><span className='text'>Sipariş Listesi</span></Button>

                <div className='input-group' >
                  <span>Sipariş No :</span>
                  <FormInput nomargin={true} placeholder="Sipariş No" type='text' />
                </div>

                <div className='input-group' >
                  <span>Satış Tipi :</span>
                  <FormInput nomargin={true} placeholder="Satış Tipi" type='text' />
                </div>

                <div className='input-group' >
                  <span>Satış Bölgesi :</span>
                  <FormInput nomargin={true} placeholder="Satış Bölgesi" type='text' />
                </div>

                <div className='input-group' >
                  <span>Satış Temsilcisi :</span>
                  <FormInput nomargin={true} placeholder="Sevn Onaylayan" type='text' />
                </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Sevkiyat Bilgileri</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <AccordionForm>

                <div className='input-group' >
                  <span>Sevk Tipi :</span>
                  <FormInput children={['Yükleme','İndirme']} nomargin={true} placeholder="Sevk Tipi" type='combo' />
                </div>

                <div className='input-group' >
                  <span>Sevk Tarihi :</span>
                  <FormInput nomargin={true} placeholder="Sevn Saat" type='datetime-local'  />
                </div>

                <div className='input-group' >
                  <span>İrsaliye No :</span>
                  <FormInput nomargin={true} placeholder="İrsaliye No" type='text'  />
                </div>

                <div className='input-group' >
                  <span>İrsaliye Tarihi :</span>
                  <FormInput nomargin={true} placeholder="İrsaliye Saat" type='date'  />
                </div>

                <div className='input-group' >
                  <span>Nakliye Firma :</span>
                  <FormInput nomargin={true} placeholder="Nakliye Firma" type='text'  />
                </div>

                <div className='input-group' >
                  <span>Sürücü Adı :</span>
                  <FormInput nomargin={true} placeholder="Sürücü Adı" type='text'  />
                </div>

                <div className='input-group' >
                  <span>Plaka :</span>
                  <FormInput nomargin={true} placeholder="Plaka" type='text'  />
                </div>

                <div className='input-group' >
                  <span>Nakliye Bedeli :</span>
                  <FormInput nomargin={true} placeholder="Nakliye Bedeli" type='number'  />
                </div>

                <div className='input-group' >
                  <span>Personel :</span>
                  <FormInput nomargin={true} placeholder="Personel" type='combo' children={['Ali','Veli']}  />
                </div>

                <div className='input-group' >
                  <span>SKF No :</span>
                  <FormInput nomargin={true} placeholder="SKF No" type='text' />
                </div>

                <div className='input-group' >
                  <span>Sevkiyat Not :</span>
                  <FormInput nomargin={true} placeholder="Sevkiyat Not" type='text' />
                </div>

                <div className='input-group' >
                  <span>Hasar Not :</span>
                  <FormInput nomargin={true} placeholder="Hasar Not" type='text' />
                </div>

                <div className='input-group' >
                  <span>Fotoğraf :</span>
                  <FormInput nomargin={true} placeholder="Fotoğraf" type='checkbox' />
                </div>



              </AccordionForm>

            </AccordionDetails>
          </Accordion>


          
          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Sevkiyat Oluştur'}</Button>
          </div>



          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}
