import React,{useState,useEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {    faChargingStation, faSearch,faTimes,faArrowDown } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import ThreeDot from '../../components/Form/ThreeDot'
import { useHistory } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {AccordionForm} from '../../styles/Content'

import Chart from 'react-google-charts'
import { Helmet } from 'react-helmet';
//
export default function EquipmentSuitability() {  
  const [search,setSearch]=useState('')
  const [startDate,setStartDate]=useState('')
  const [endDate,setEndDate]=useState(0)

  const getTimeStamp=(date)=>{
    return new Date(date).getTime() / 1000 
  }
  
  const {sidebar}=useSelector(state=>state.site)

  const history=useHistory()

  const searchRef=useRef(null)
  
  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Türü" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  const rows = [
    ["1","aaaaaa","jenerator",new Date(2015, 2, 1),new Date(2016, 2, 2),5 * 60 * 1000,100,null],
    ["2", "sdafasdfsaf dafgad", null, new Date(2015, 3, 2),new Date(2016, 3, 3), 70 * 60 * 1000, 100, null],
    ["3","aasdfasf",null,new Date(2015, 4, 4),new Date(2016, 4, 3),10 * 60 * 1000,100,null,],
    ["4", "bbbbb", "jenerator", new Date(2015, 5, 4),new Date(2016, 5, 5), 45 * 60 * 1000, 75, null],
    ["5", "sadfa asdfsd", null, new Date(2015, 6, 5),new Date(2016, 6, 6), 10 * 60 * 1000, 0, null],
    ["6", "gadfg adfgadf", null, new Date(2015, 6, 5),new Date(2016, 6, 7), 10 * 60 * 680, 0, null],
    ["7", "agfdagf adfgad",null, new Date(2015, 7, 8),new Date(2016, 7, 8),  2 * 60 * 1000, 0, null],
    ["8", "cbncvb cxvbxcb",null, new Date(2015, 8, 9),new Date(2016, 8, 10),  2 * 35 * 750, 0, null],
  ];

  const clearFilter=()=>{
    setStartDate('')
    setEndDate('')
  }

  const getFilteredRows=(rowData)=>{
    return rowData.filter((row)=>{
      if(startDate=='' && endDate=='' && search==''){
        return true
      }
      if(startDate=='' && endDate==''){
        if(row[1].toLowerCase().indexOf(search.toLowerCase())>-1){
          return true
        }
      }else{
        if(getTimeStamp(row[3])>=getTimeStamp(startDate=='' ? '01.01.1928' : startDate) && getTimeStamp(row[4])<=getTimeStamp(endDate=='' ? new Date() : endDate)){
          if(row[1].toLowerCase().indexOf(search.toLowerCase())>-1){
            return true
          }else{
          }
        }
      }
    })
  }

  return (
    <React.Fragment >
      <Menu/>
      <Helmet>
        <title>Ekipman Uygunluk</title>
      </Helmet>
      <Content sidebar={sidebar}  >
        
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title text={<><FontAwesomeIcon icon={faChargingStation} /> Ekipman Uygunluk</>} />
          
          {/* <div style={{width:'105%'}}>

            <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara" 
            refer={searchRef}
            inputClass='search-input' rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } />
            
          </div> */}

          <Accordion
          className='accordion-form'
            style={{flexGrow:1,marginBottom:15}}
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Arama Filtresi</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>
                  <Button onClick={()=>clearFilter()} variant="contained" className='clear-filter' ><FontAwesomeIcon icon={faTimes}/><span className='text'></span></Button>
                  <div className='input-group input-search'>
                    <FormInput 
                    value={search}
                    setValue={setSearch}
                    nomargin={true}
                    placeholder="Tüm alanlarda ara" 
                    refer={searchRef}
                    />
                  </div>

                  <div className='input-group' >
                    <span >Ekipman Türü</span>
                    <FormInput type="combo" children={['a','b']}  value={startDate} setValue={setStartDate} rightIcon={''!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setStartDate('')}/> : null} />  
                  </div>

                  <div className='input-group' >
                    <span >Başlangıç Tarihi</span>
                    <FormInput type="datetime-local"  value={startDate} setValue={setStartDate} rightIcon={''!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setStartDate('')}/> : null} />  
                  </div>
                
                  <div className='input-group'  >
                    <span >Bitiş Tarihi</span>
                    <FormInput type="datetime-local"  value={endDate} setValue={setEndDate} rightIcon={''!= '' ? <FontAwesomeIcon icon={faTimes} onClick={()=>setEndDate('')}/> : null} />  
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <Chart
            width={'100%'}
            height={'55vh'}
            chartType="Gantt"
            className='chart'
            loader={<div>Grafik yükleniyor</div>}
            data={[columns, ...(getFilteredRows(rows))]}
            chartLanguage={'tr'}
            nullMessage={'Grafik yükleniyor'}
            chartEvents={[
              {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 0) {
                    return;
                  }
                  const row = selection[0].row;
                  const taskId = rows[row][0];
                  history.push(`/ekipman/guncelle/${taskId}`)
                }
              }
            ]}
            legendToggle
          />
          
          <div className='addButton'>
            {/* <Button type='submit' color="primary" variant="contained">Ekipman Oluştur</Button> */}
          </div>

          </form>

        </ContentForm>

      </Content>
    </React.Fragment>
  )
}
