import React, { useEffect } from 'react'
import {FormElement} from '../../styles/Form'
import Checkbox from '@mui/material/Checkbox';

export default function Input(props) {
  
  const [value,setValue]=React.useState('')
  const [checkValue,setCheckValue]=React.useState(false)

  const [searchChild,setSearchChild]=React.useState('')

  const thisChange=(e)=>{
    if(props.setValue){
      props.setValue(e.target.value)
    }else{
      setValue(e.target.value)
    }
  }
  const comboChange=(e)=>{
    const checker=props.children.filter(x=> x.toLowerCase().includes(e.target.value.toLowerCase()))
    if(checker.length>0){
      thisChange(e)
    }else{
      e.preventDefault()
    }
    
  }
  useEffect(()=>{
    if(props.value){
      setSearchChild(props.value)
    }else{
      setSearchChild(value)
    }

  },[props.value,value])
  const getInput=()=>{
    return (
      <input 
        onChange={(e)=>{
          if(props.onChange){
            props.onChange(e)
          }else if(props.type && props.type=='combo'){
            comboChange(e)
          }else{
            //alert(e.target.value)
            thisChange(e)
          }

        }}
        onKeyUp={props.onKeyUp ? props.onKeyUp : null}
        type={props.type ? props.type : 'text'} 
        required={props.required ? true : false}
        placeholder={props.placeholder ? props.placeholder : 'Form Element'}
        enable={props.enable ? props.enable.toString() : 'true'}
        disabled={props.disabled ? props.disabled : false}
        style={(props.styles  ? (props.styles) : null),{position:props.type=='file' ? 'fixed' : undefined,left:props.type=='file' ? '-300vw' : undefined,top:props.type=='file' ? '-300vw' : undefined}}
        value={props.value ? props.value : value} 
        className={props.inputClass ? props.inputClass : null}
        ref={props.refer ? props.refer : null}
        
        />
    )
  }
  return (
    <FormElement style={{marginRight:props.nomargin ? '3%' : null,...props.mainStyle}} paddingLeft={props.leftIcon ? 30 : 0} paddingRight={props.rightIcon ? 30 : 0}>
      
      {props.leftIcon ? <span className='left'>{props.leftIcon}</span> : null}
      {props.rightIcon ? <span className='right'>{props.rightIcon}</span> : null}
      
      {
        props.type=='file' ?
        <label className='img-upload' style={{width:'100%'}}>
          <div >Görsel Seçmek için Tıkla</div>
          {
            getInput()
          }
        </label>
        :
        (
          props.type=='checkbox' ?
          <Checkbox 
          onChange={(e)=>{
            if(props.onChange){
              props.onChange(e)
            }else if(props.setValue){
              props.setValue(!(props.value ? props.value : value))
            }
          }}
          checked={props.checked!=undefined && props.checked!=null ? (props.checked==true ? true : false) : (props.value!=undefined && props.value!=null ? (props.value==true ? true : false) : checkValue)}
          type={props.type ? props.type : 'text'} 
          enable={props.enable ? props.enable.toString() : 'true'}
          disabled={props.disabled ? props.disabled : false}
          style={(props.styles  ? (props.styles) : null),{position:props.type=='file' ? 'fixed' : undefined,left:props.type=='file' ? '-300vw' : undefined,top:props.type=='file' ? '-300vw' : undefined}}
          value={props.value ? props.value : value} 
          className={props.inputClass ? props.inputClass : null}
          ref={props.refer ? props.refer : null}
          />

          :
          getInput()
        )
      }
      {
      props.children ? 
        <div >
          <ul>
            {/* <input value={searchChild} onChange={(e)=>setSearchChild(e.target.value)} /> */}
            {
              (
                (
                  props.type && (props.type=='combo') ?
                  (
                    props.children.filter(childItem=>{
                      if(childItem.toLowerCase().includes(searchChild.toLowerCase())){
                        if(props.value && childItem.indexOf(props.value)>-1){
                          return true
                        }else if((childItem.toLowerCase()).includes((value.toLowerCase()))){
                          return true
                        }
                      }
                      return false
                    })
                  )
                  :
                  props.children  
                )
              ).map((child,index)=>{
              return <li onClick={()=>{
                if(props.setValue){
                  props.setValue(child)
                }else{
                  setValue(child)
                }
                if(props.childChange){
                  props.childChange(child)
                }
              }} key={index}>{child}</li>
            })
            }
          </ul>
        </div>
      : null
      }
    </FormElement>
  )
}
