import React from 'react'
import {ErrorPage} from '../../styles/Error'
import { useHistory } from 'react-router-dom';
export default function NotFound() {
  const history=useHistory()
  const [sayac,setSayac]=React.useState(5)
  React.useEffect(() => {
    if(sayac<1){
      history.goBack()
    }
    setTimeout(() => {
      setSayac(sayac-1)
    }, 1000);
    
  }, [sayac])
  return (
    <ErrorPage>
      <h1>404</h1>
      <h2>Sayfa Bulunamadı</h2>
      <h3>Önceki sayfaya yönlendiriliyorsunuz  {sayac}</h3>
    </ErrorPage>
  )
}
