import styled from "styled-components";
import { rgba } from "polished";
const ErrorPage=styled.div`
  width:100%;
  min-height:100vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background-color:${({theme})=>rgba(theme.errorPageBackground,0.8)};
  h1{
    font-size:7rem;
    font-weight:bold;
    color:${({theme})=>theme.errorText};
  }
  h2{
    font-size:5rem;
    color:${({theme})=>theme.errorText};
  }
  h3{
    margin-top:1rem;
    font-size:1rem;
    color:${({theme})=>rgba(theme.errorText,0.6)};
  }
`

export {ErrorPage}