import React from 'react'
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PopupDialog({open,setOpen,icon,title,content,setConfirm}) {
  return (
    <Dialog
        open={open}
        onClose={()=>setOpen(!open)}
      >
      <DialogTitle >
        <span style={{marginRight:30}}>{icon}</span> {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={()=>setOpen(!open)}>İptal Et</Button>
        <Button variant="contained" color="primary" onClick={setConfirm} autoFocus>
          Onayla
        </Button>
      </DialogActions>
    </Dialog>
  )
}
