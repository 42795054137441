import styled from "styled-components";
import background from '../images/yellow_light.jpeg';
import { makeStyles } from '@material-ui/core/styles';
import { rgba } from "polished";
const AuthContainer=styled.div`
  width:100%;
  height:100%;
  min-height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  .error{
    background-color:${({theme})=>rgba(theme.errorBackground,0.7)};
    color:${({theme})=>theme.errorText};
    padding:10px;
  }
  background-size:cover;
  form{
    background-color:rgba(255,255,255,.8);
    padding:40px;
    border-radius:15px;
    display:flex;
    flex-direction:column;
    div,button{
      margin-bottom:20px;
    }
    width:30%;
    @media (max-width:930px){
      width:70%;
    }
    @media (max-width:800px){
      width:60%;
    }
    background-color:;
    button span{
    }
    .bottomarea{
      display:flex;
      justify-content:space-around;
      a{
        text-decoration:none;
        color:#777;
      }
    }
  }
`
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    "&:hover:": {
      cursor: "pointer"
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },

  cssLabel: {
    color : 'red'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.warning.main} !important`,
    }
  },

  cssFocused: {
    color: `${theme.palette.warning.main} !important`,
    color:'#000'
  },

  notchedOutline: {
    borderWidth: '1px',
    color:'red',
    borderColor: ' !important'
  },
}));




export {AuthContainer,useStyles}