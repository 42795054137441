import React,{useState,useEffect} from 'react'
import supportLogo from '../../images/support.png'
import { SupportContainer } from '../../styles/Support'
import { useLocation } from 'react-router-dom'
import { Fab } from '@mui/material'
import SupportBox from './SupportBox'
import {  useSelector } from 'react-redux'
import PWA from './Pwa'
export default function Support(props) {
    
  const [open,setOpen]=useState(false)
  const location = useLocation()
  const {user} = useSelector(state=>state.auth)
  return (
    <>
   
    {
      location.pathname.indexOf('giris-yap') === -1 ?
        <>
          {/* <SupportContainer >
            <Fab onClick={(e)=>{setOpen(true)}} variant='circular' style={{right:!open ? undefined : '-100vw' }}>
              <img src={supportLogo} />
            </Fab>
            <SupportBox id={JSON.parse(user).id} setOpen={setOpen} open={open} />
          </SupportContainer> */}
        </>
        :
        null
    }
    </>
  )
}
