import { createSlice } from "@reduxjs/toolkit";

import { LightTheme,DarkTheme } from "../../styles/Theme";
import defaultImage from '../../images/default'
const getDefaultImage=()=>{
  localStorage.setItem('background',defaultImage)
  return defaultImage
}
export const site = createSlice({
  name:'site',
  initialState:{
    theme:localStorage.getItem('theme')!=undefined ? localStorage.getItem('theme') : "LightTheme",
    language:localStorage.getItem('language') && 'tr',
    slider:{front:'22,88,103',center:'253,29,29',centerlast:'41,126,231',end:'221,18,46'},
    sidebar:localStorage.getItem('sidebar')!=undefined  ? (localStorage.getItem('sidebar')=='false' ? false : true)  : true,
    volume:localStorage.getItem('volume')!=undefined ? localStorage.getItem('volume') : 1,
    background:localStorage.getItem('background')!=undefined ? localStorage.getItem('background') : getDefaultImage(),
  },
  reducers:{
    changeBackground:(state,action)=>{
      state.background=action.payload
      if(action.payload==''){
        state.background=defaultImage
      }
      localStorage.setItem('background',state.background)
    },
    changeVolume: (state, action) => {
      state.volume=action.payload
      localStorage.setItem('volume',action.payload)
    },
    changeSidebar:(state)=>{
      localStorage.setItem('sidebar',!state.sidebar)
      state.sidebar=!state.sidebar
    },
    changeSlider: (state,action) => {
      state.slider=action.payload
    },
    lightMode : (state) => {
      state.theme = LightTheme
    },
    darkMode : (state) => {
      state.theme = DarkTheme
    },
    changeTheme: (state) =>{
      state.theme = state.theme=="LightTheme" ? "DarkTheme" : "LightTheme"
      localStorage.setItem('theme',state.theme)
    },
    english: (state) => {
      state.language = 'en'
    },
    turkish: (state) => {
      state.language = 'tr'
    },
    changeLanguage: (state) =>{
      state.language = state.language=='tr' ? 'en' : 'tr'
    }

  }
})

export const {changeVolume,changeBackground,lightMode,darkMode,changeTheme,turkish,english,changeLanguage,changeSlider,changeSidebar} = site.actions
export default site.reducer