import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ShortcutContent} from '../../../styles/Content'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import Items from './Items'
export default function ShortcutArea() {
  const [search,setSearch]=useState('')

  const handleSearch=(e)=>{
    if(search!=''){
      setSearch('')
    }
  }

  return (
    <ShortcutContent>
      <div className='shortcut-content'>
        <div className='head'>
          <input type='text' value={search} onChange={(e)=>setSearch(e.target.value)} placeholder='Arama Yap' />
          <div className='icon' onClick={handleSearch}><FontAwesomeIcon icon={search!='' ? faTimes : faSearch} /></div>
        </div>
        
        <div className='shortcut-items'>
          <Items search={search}/>
        </div>
      
      </div>

    </ShortcutContent>
  )
}
