import React from 'react'
import { Fab } from '@mui/material';
import {  faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react'
export default function ThreeDot(props) {
  const [open,setOpen] = React.useState(false);
  return (
    <>
    
    <Menu >
      <Menu.Button
        className='threeDot-btn'
      onClick={()=>{
          setOpen(!open);
        }}
        style={{background:'transparent',border:'none'}}
        >

        
          <FontAwesomeIcon icon={faEllipsisV} />

      </Menu.Button>
      <Menu.Items
        className='dot-menu-items'
        >
        <Menu.Item>

          {
            props.items
          }
        
        </Menu.Item>
        
        
      </Menu.Items>
    </Menu>
    </>
  )
}
