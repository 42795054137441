import React,{useState} from 'react'
import { useSoundEffect } from './Sounds';
export default function NotifyItem(props) {
  const [open,setOpen]=useState(false)
  const {click}=useSoundEffect()

  return (
    <div  className={open ? 'close-flex-item' : ''}>
      <h1 onClick={()=>{
        setOpen(!open);
          click()
      }}> {props.name} {props.icon}  </h1>
      <ul  >
      {
        props.items.map((subitem,index)=>{
          return (
            <li key={'flex-sub-item-'+subitem.title+index} ><h3>{subitem.title} : </h3>{subitem.content}</li>
          )
        })
      }
      </ul>
    </div>
  )
}
