import React from 'react'
import useSound from 'use-sound'
import errorSound from '../../sounds/error.mp3'
import successSound from '../../sounds/success.mp3'
import lampOnSound from '../../sounds/lamp/lamp-on.mp3'
import lampOffSound from '../../sounds/lamp/lamp-off.mp3'
import clickSound from '../../sounds/click.mp3'
import click2Sound from '../../sounds/click2.mp3'
import screenshotSound from '../../sounds/screenshot.mp3'
import slideOn from '../../sounds/slide-on.mp3'
import lockSound from '../../sounds/lock.mp3'
import {useSelector} from 'react-redux'
export const useSoundEffect = (sound) => {
  const {volume} = useSelector(state=>state.site)
  const settings={
    volume:volume
  }
  const [errorPlay] = useSound(errorSound,settings)
  const [successPlay] = useSound(successSound,settings)
  const [lampOn] = useSound(lampOnSound,settings)
  const [lampOff] = useSound(lampOffSound,settings)
  const [click] = useSound(clickSound,settings)
  const [click2] = useSound(click2Sound,settings)
  const [screenshot] = useSound(screenshotSound,settings)
  const [onSlide] = useSound(slideOn,settings)
  const [lockPlay] = useSound(lockSound,settings) 
  
  return {errorPlay,successPlay,lockPlay,screenshot,lampOn,lampOff,click,click2,onSlide}
}
