import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'

import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,  faExclamation,  faUserPlus } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';

import GroupSwitch from '../../components/Group/GroupSwitch'
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';

import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet'

export default function GroupAdd() {

  const {errorPlay,successPlay} = useSoundEffect()

  const {sidebar}=useSelector(state=>state.site)
  const [selectedPerms,setSelectedPerms] = useState([])
  const [perms,setPerms]=useState([])
  const [name,setName]=useState('')
  const [detail,setDetail]=useState('')

  const [loading,setLoading]=useState(false)
  
  const [addDialog,setAddDialog]=useState(false)
  const [addConfirm,setAddConfirm]=useState(false)

  
  const confirmAdd=async()=>{
    let set=await addGroup(null,true)
  }

  const addGroup=async(e,confirmed=false)=>{
    if(e!=null){
      e.preventDefault()
    }
    if(addConfirm==false && confirmed==false ){
      setAddDialog(true)
      return
    }else{
      setAddConfirm(false)
      setAddDialog(false)
    }
    setLoading(true)
    if(name!=''){
      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_ADD_USER_GROUP,
        data: {
          name: name,
          detail: detail
        },
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      }).then(response=>{
        if(selectedPerms.length>0){
          addPerms(response.data.data.id)
        }else{
          setLoading(false)
          setSelectedPerms([])
          setName('')
          setDetail('')
          successPlay()
        }        
        toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        
      }).catch(error=>{
        toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
        setLoading(false)
      })
    }else{
      setLoading(false)
      errorPlay()
      toast.error('Tüm alanları doldurunuz')
    }
  }

  const addPerms=async(id)=>{
    if(perms.length>0){
      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_ADD_GROUP_PERMS.replace('-grupid-',id)),
        data: {
          perms:selectedPerms
        },
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      }).then(response=>{
        toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        setLoading(false)
        setSelectedPerms([])
        setPerms([])
        setName('')
        setDetail('')
        successPlay()
      }).catch(error=>{
        setLoading(false)
        toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
      })
    }
  }

  useEffect(() => {
    if(perms.length===0){
      axios({
        method: 'get',
        url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_PERMISSIONS,
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
      })
      .then((response)=>{
        setPerms(response.data.data)
      })
      .catch((error)=>{
        toast.error(error.response.data.message)
      })
    }
  },[perms])
 
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Grup Oluştur</title>
      </Helmet>
      <Dialog
        open={addDialog}
        setOpen={setAddDialog}
        setConfirm={confirmAdd}
        selectedId={1}
        content={<>Seçili yetkiler ile yeni bir kullanıcı grubu oluşturma işlemini onaylıyor musunuz ?</>} 
        title={<>Yeni Grup Tanımı </>}
        icon={<FontAwesomeIcon icon={faExclamation} />}  />

      <Content sidebar={sidebar}>
        
        <ContentForm>
          <form onSubmit={addGroup}>
          <Title text={<><FontAwesomeIcon icon={faUserPlus} /> Yeni Grup Oluştur</>} />
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Grup Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                
                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={name} setValue={setName} nomargin={true} placeholder="Grup Adı" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='text' name='name' />
                  </div>

                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={detail} setValue={setDetail} nomargin={true} placeholder="Grup Açıklaması" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='text' name='name' />
                  </div>

                  {
                    perms.length>0 ?
                    <GroupSwitch data={perms} perms={selectedPerms} setPerms={setSelectedPerms} />
                    : 
                    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',height:100}}>
                      <Sentry size={30} color={'#000'} />
                    </div>
                  }
                  
              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          
          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Grup Oluştur'}</Button>
          </div>



          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}
