import styled from "styled-components";

const CalculatorContainer=styled.div`
  position:fixed;
  z-index:90;

  .calc{
    width: 300px;
    background-color: #202020;
    .head{
      display: flex;
      height: 50px;
      cursor: pointer;
      button{
        height: 50px;
      }
      align-items: center;
      justify-content: space-between;
      b{
        color: #fff;
        font-size: 18px;
      }
    }
    .display{
      color:#fff;
      width: calc(100% - 10px);
      display: flex;
      justify-content: flex-end;
      padding:10px 0px;
      padding-right: 10px;
      font-size:15px;
    }
    .body{
      position: relative;
      .history{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #eee;
        width: 100%;
        z-index: ${({history})=>!history?-1:3};
        opacity: ${({history})=>!history?0:1};
        transition: all 0.3s ease;
        height: 100%;
      }
      input{
        overflow: scroll!important;
        background-color: transparent;
        width: 260px;
        z-index: 1;
        border:0;
        outline:0;
        padding:0;
        margin:0;
        color:#fff;
        font-size:50px;
        padding:20px;
        text-align:right;
        caret-color: transparent;
      }
      .buttons div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        background-color: #303030;
        button{
          color: #fff!important;
          width: calc(100% / 4);
          font-size: 20px;
          height: 60px;
          border-radius: 0px;
        }
        .zero{
          width: calc(100% / 2);
        }
      }
      .buttons div button:last-child{
        font-size: 25px;
        background-color: #F8A20D;
      }
      .buttons div:first-child {
        background-color: #3f3f3f;
      }
    }
  }
`

export {CalculatorContainer}