import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'

import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faFolderPlus, faHome } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';

import FormInput from '../../components/Form/Input'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { useSoundEffect } from '../../components/Content/Sounds';
import { toast } from 'react-toastify'




export default function EquipmentAdd(props) {
  
  const {sidebar}=useSelector(state=>state.site)

  const {errorPlay,successPlay}=useSoundEffect()
  
  const [equipmentType, setEquipmentType] = useState('')
  const [options, setOptions] = useState([])
  const [groupNames, setGroupNames] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')
  const [settedValues, setSettedValues] = useState([])

  const getOptions=(optId)=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_EQUIPMENT_OPTIONS.replace('-type-',optId)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(async res=>{
      setOptions(res.data.data)
      let groups=[]
      const set=await res.data.data.columns.map(item=>{
        if(item.groupName!=null && groups.indexOf(item.groupName) && !groups.includes((item.groupName.replace(/\s/g, '')))){
          groups.push(item.groupName)
        }
      })
      setGroupNames([...new Set(groups)])
      setSelectedGroup(groups[0])
    })
  }
  const getEquipmentInputs=()=>{
    switch(equipmentType){
      case 'Jeneratör Seti':
        // return <GeneratorInputs data={generatorData} />
      default:
        return <><center><h1>Lütfen geçerli bir ekipman türü seçiniz.</h1></center></>
    }
  }
  const getEquipmentType=()=>{
    switch(equipmentType){
      case 'Jeneratör Seti':
        return 1
      case 'Aydınlatma Kulesi':
        return 2
      case 'Yük Bankası':
        return 3
      case 'Transfer Panosu':
        return 4
      case 'Dağıtım Panosu':
        return 5
      case 'Yakıt Tankı':
        return 6
      case 'Trafo':
        return 7

      default:
        return false
    }
  }
  useEffect(() => {
    if(equipmentType!=''){
      let etype=getEquipmentType()
      if(etype!=false){
        getOptions(etype)
      }else{
        setOptions([])
        setGroupNames([])
        setSelectedGroup('')
      }
    }
  }, [equipmentType])
  
  const createEquipment=(e)=>{
    e.preventDefault()
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'post',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_ADD_EQUIPMENT.replace('-type-',getEquipmentType())),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      },
      data:{
        data:settedValues
      }
    })
    .then(response=>{
      successPlay()
      setSettedValues([])
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
      console.log("err:",error,error.response);
    })

  }

  return (
    <>
      <Menu/>
      <Helmet>
        <title>Ekipman Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        
        <ContentForm>
          <form onSubmit={createEquipment}>
          <Title text={<><FontAwesomeIcon icon={faFolderPlus} /> Ekipman Oluştur</>} />
          <Accordion
          className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Ekipman Oluşturma Formu <span style={{fontSize:14,fontStyle:'italic',marginLeft:20}}>( <span style={{color:'red',fontWeight:'bold'}}>*</span> işareti zorunlu alanlardır.)</span></Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>

                <div className='input-group'>
                  <span>Ekipman Türü :</span> 
                    <FormInput 
                    type={'combo'}
                    placeholder={'Ekipman Türü'}
                    setValue={setEquipmentType}
                    value={equipmentType}
                    
                    children={['Jeneratör Seti','Aydınlatma Kulesi','Yük Bankası','Transfer Panosu','Dağıtım Panosu','Yakıt Tankı','Trafo']}
                    />
                </div>
                {
                  options.columns ?
                    options.columns.map((item,index)=>{
                      if(item.groupName==null){
                        return (
                          <div key={'item-'+item.columnID} className='input-group' key={`equiepment-general-${index}`}>
                            <span>{item.displayName} {item.required ? <span style={{color:'red'}}>*</span> : null} : </span> 
                            <FormInput 
                            type={item.valueType}
                            value={settedValues.find(it=>it.columnID==item.columnID)?settedValues.find(it=>it.columnID==item.columnID).value:''}
                            required={item.required ? true : false}
                            
                            setValue={(e)=>{
                              if(settedValues.find(st=>{
                                if(st.columnID==item.columnID){
                                  return true
                                }
                              })){
                                let newSettedValues=settedValues.map(st=>{
                                  if(st.columnID==item.columnID){
                                    st.value=e.target ? e.target.value : e
                                  }
                                  return st
                                })
                                setSettedValues(newSettedValues)
                              }else{
                                setSettedValues([...settedValues,{columnID:item.columnID,value:e.target ? e.target.value : e}])
                              }
                            }}
                            placeholder={item.displayName} 
                            children={item.valueType=='combo' && item.values.length>0 ? item.values.map(value=>value.value) : undefined}
                            {...item.props}
                            />
                          </div>
                        )
                        
                        
                      }
                    })
                  :
                  <></>
                }

              </AccordionForm>
              <AccordionForm>
                {
                  groupNames.map((item,index)=>{
                    return (
                      <Button key={'item-'+item+'-'+index} variant={selectedGroup==item ? 'contained' : 'outlined'} onClick={()=>setSelectedGroup(item)} color={selectedGroup==item ? 'primary' : 'secondary'} key={`equiepment-general-${index}`}>
                        {item}
                      </Button>
                    )
                  })
                }
              </AccordionForm>

              <AccordionForm>

                {
                  options.columns ?
                    options.columns.map((item,index)=>{
                      if(item.groupName!=null){
                        return (
                          <div key={'item-'+item.columnID} 
                          style={{position:item.groupName==selectedGroup ? 'relative' : 'fixed',opacity:item.groupName==selectedGroup ?1:0,top:item.groupName==selectedGroup ? undefined : '-5000vh',left:item.groupName==selectedGroup ? undefined : '-5000vh'}} className='input-group' key={`equiepment-general-${index}`}>
                            <span>{item.displayName} {item.required ? <span style={{color:'red'}}>*</span> : null}  : </span> 
                            <FormInput 
                            
                            type={item.valueType}
                            value={settedValues.find(it=>it.columnID==item.columnID)?settedValues.find(it=>it.columnID==item.columnID).value:''}
                            required={item.required ? true : false}
                            setValue={(e)=>{
                              if(settedValues.find(st=>{
                                if(st.columnID==item.columnID){
                                  return true
                                }
                              })){
                                let newSettedValues=settedValues.map(st=>{
                                  if(st.columnID==item.columnID){
                                    st.value=e.target ? e.target.value : e
                                  }
                                  return st
                                })
                                setSettedValues(newSettedValues)
                              }else{
                              
                                setSettedValues([...settedValues,{columnID:item.columnID,value:e.target ? e.target.value : e}])
                              }
                            }}
                            placeholder={item.displayName} 
                            children={item.valueType=='combo' && item.values.length>0 ? item.values.map(value=>value.value) : undefined}
                            {...item.props}
                            />
                          </div>
                        )
                      }
                    })
                  :
                  <></>
                }

              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          

          <div className='addButton'>
            <Button type='submit' color="primary" variant="contained">Ekipman Oluştur</Button>
          </div>



          </form>

        </ContentForm>

      </Content>
    </>
  )
}
