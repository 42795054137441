import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name:'auth',
  initialState:{
    status:localStorage.getItem('status')!=null && localStorage.getItem('status')!=undefined  ? localStorage.getItem('status') : false,
    user:localStorage.getItem('user')!=null && localStorage.getItem('user')!=undefined  ? localStorage.getItem('user') : [],
    login:localStorage.getItem('login')=='true' ? true : false,
    token:localStorage.getItem('token')!=null && localStorage.getItem('token')!='' && localStorage.getItem('token')!=undefined ? localStorage.getItem('token') : null,
    routes:localStorage.getItem('routes')!=null && localStorage.getItem('routes')!=undefined ? JSON.parse(localStorage.getItem('routes')) : []
  },
  reducers:{
    setRoutes:(state,action)=>{
      state.routes=action.payload
      localStorage.setItem('routes',JSON.stringify(action.payload))
    },
    logout: (state) => {
      state.login = false
      localStorage.removeItem('login')
      localStorage.removeItem('user')
      localStorage.removeItem('status')
      localStorage.removeItem('token')
      localStorage.removeItem('routes')
    },
    login: (state, action) => {
      state.login = true
      localStorage.setItem('login','true')
      localStorage.setItem('user',JSON.stringify(action.payload.user))
      localStorage.setItem('status',action.payload.status)
      localStorage.setItem('token',action.payload.token)
    }
  }
})

export const {login,logout,setRoutes} = auth.actions
export default auth.reducer