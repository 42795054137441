import React,{useLayoutEffect} from 'react'

import {useDispatch} from 'react-redux'

import {logout} from '../../components/stores/Auth'
import { useHistory } from 'react-router-dom'
export default function LogoutPage() {

  const dispatch = useDispatch()
  const history=useHistory()
  useLayoutEffect(() => {

    dispatch(logout())
    history.push('/')

  }, [])

  return (
    <>
      
    </>
  )
}
