import React,{useState,useLayoutEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faArrowDown, faUserShield, faTimesCircle, faExclamationTriangle, faLuggageCart } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom';
import EditButton from '../../components/Form/EditButton'
import axios from 'axios'
import {AccordionForm} from '../../styles/Content'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { Sentry } from 'react-activity'
import GroupSwitch from '../../components/Group/GroupSwitch'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet'
export default function GroupDetail(props) {  

  const {successPlay,errorPlay} = useSoundEffect()

  const [loading,setLoading]=useState(true)

  const [groupNames, setGroupNames] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')
  const [settedValues, setSettedValues] = useState([])

  const buttonRef=useRef(null)
  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)

  const [columns,setColumns]=useState([])

  const history=useHistory()

  const [name,setName]=useState()

  const [group,setGroup]=useState(null)
  let {id} = useParams()
  id=id==undefined ? props.id : id

  const getColumnValue=(data)=>{
    
    switch (data.valueType) {
      case 'combo':
        if(data.values!=null && data.values.find(x=>x.selected==true)){
          //console.log(data,data.values.find(x=>x.selected==true).value);
          return data.values.find(x=>x.selected==true).value
        }
        break;
      case 'text':
        if(data.values!=null){
          return data.values[0].value
        }
        break;
      case 'checkbox':
        if(data.values!=null){
          return data.values[0].value
        }
        break;
    
      default:
        break;
    }
    return ""
  }
  const setValues=async(data)=>{
    let news=[]
    data.map(x=>{
      news.push({columnID:x.columnID,value:getColumnValue(x)})
    })
    setSettedValues(news)
  }
  const getEquipment=()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_EQUIPMENT_DETAIL.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then( response=>{
      setLoading(false)
      setColumns(response.data.data.columns)
      setSettedValues(response.data.data.columns)
      setName(response.data.data.name)
      let groups=[]
      response.data.data.columns.map(item=>{
        if(item.groupName!=null && !groups.includes((item.groupName.replace(/\s/g, '')))){
          groups.push(item.groupName)
        }
      })
      setGroupNames([...new Set(groups)])
      setSelectedGroup(groups[0])
      setEdit(false)
      setGroup(response.data.data)
      setValues(response.data.data.columns)


    })
    
  }

  useLayoutEffect(() => {
    if(columns==null || columns.length<1){
      getEquipment()
    }
  }, [columns])

  const updateEquipment=(e)=>{
    e.preventDefault()
    if(edit){
      if(name!=''){
          const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
          axios({
            method:'post',
            url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_UPDATE_EQUIPMENT.replace('-id-',id)),
            headers:{
              'Authorization':localStorage.getItem('token'),
              'Content-Type':'application/json'
            },
            data:{
              data:settedValues.filter(x=>x.value!=''),
            }
          })
          .then(response=>{
            successPlay()
            setEdit(false)
            toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
          })
          .catch(error=>{
            errorPlay()
            toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
            console.log("err:",error,error.response);
          })

        
      }else{
        errorPlay()
        toast.error('Tüm alanları doldurmalısınız.')
      }
      
    }else{
      setEdit(true)
    }
    
  }

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  
  const confirmDelete=async()=>{
    let set=await deleteEquipment(true)
  }
  const deleteEquipment=async(confirmedDelete=false)=>{
    const seter=await setEdit(false)
    if(deleteConfirm==false && confirmedDelete!=true ){
      setDeleteDialog(true)
      return
    }else{
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'post',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_EQUIPMENT.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      successPlay()
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      history.push('/ekipman/liste')
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }

  

  const getDetailContent=()=>{
    return(
      <form onSubmit={updateEquipment} >
          <Title text={<><FontAwesomeIcon icon={faLuggageCart} /> Ekipman Kartı</>} icon={
          <>
          <EditButton extras={[{text:'Ekipmanı Sil',styles:{marginRight:20,backgroundColor:'#ff7979',color:'#fff'},icon:<FontAwesomeIcon icon={faTimesCircle} />,onClick:deleteEquipment}]} cancel={getEquipment}  edit={edit} clicker={updateEquipment} />
          </>} />
          <div style={{width:'105%'}}>
            {/* <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara"
            inputClass='search-input' 
            rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } /> */}
          </div>

          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Ekipman Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              {
                loading ? 
                <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <Sentry size={44} />
                </div>
                :
                <>
                  <AccordionForm>
                  {/* {JSON.stringify(columns)} */}
                  {
                    columns.length>0 ?
                    columns.map((item,index)=>{
                      if(item.groupName==null){
                          return (
                            <div key={'item-'+item.columnID} className='input-group' key={`equiepment-general-${index}`}>
                              <span>{item.displayName} {item.required ? <span style={{color:'red'}}>*</span> : null} : </span> 
                              <FormInput 
                              type={item.valueType}
                              value={settedValues.find(it=>it.columnID==item.columnID)?settedValues.find(it=>it.columnID==item.columnID).value:''}
                              required={item.required ? true : false}
                              
                              setValue={(e)=>{
                                if(settedValues.find(st=>{
                                  if(st.columnID==item.columnID){
                                    return true
                                  }
                                })){
                                  let newSettedValues=settedValues.map(st=>{
                                    if(st.columnID==item.columnID){
                                      st.value=e.target ? e.target.value : e
                                    }
                                    return st
                                  })
                                  setSettedValues(newSettedValues)
                                }else{
                                  setSettedValues([...settedValues,{columnID:item.columnID,value:e.target ? e.target.value : e}])
                                }
                              }}
                              disabled={!edit}
                              placeholder={item.displayName} 
                              children={item.valueType=='combo' && item.values.length>0 ? item.values.map(value=>value.value) : undefined}
                              {...item.props}
                              />
                            </div>
                          )
                          
                          
                        }
                    })
                    :
                    <></>
                  }
                  
                  
                </AccordionForm>
                <AccordionForm>
                  {
                    groupNames.map((item,index)=>{
                      return (
                        <Button key={'item-'+item+'-'+index} variant={selectedGroup==item ? 'contained' : 'outlined'} onClick={()=>setSelectedGroup(item)} color={selectedGroup==item ? 'primary' : 'secondary'} key={`equiepment-general-${index}`}>
                          {item}
                        </Button>
                      )
                    })
                  }
                </AccordionForm>

                <AccordionForm>

                  {
                    columns ?
                      columns.map((item,index)=>{
                        if(item.groupName!=null){
                          
                          return (
                            <div key={'item-'+item.columnID} style={{position:selectedGroup==(item.groupName.replace(/\s/g,'')) ? 'relative' : 'fixed',opacity:selectedGroup==(item.groupName.replace(/\s/g,'')) ?1:0,top:selectedGroup==(item.groupName.replace(/\s/g,'')) ? undefined : '-5000vh',left:selectedGroup==(item.groupName.replace(/\s/g,'')) ? undefined : '-5000vh'}} className='input-group' key={`equiepment-general-${index}`}>
                              <span>{item.displayName} {item.required ? <span style={{color:'red'}}>*</span> : null}  : </span> 
                              <FormInput 
                              type={item.valueType}
                              value={settedValues.find(it=>it.columnID==item.columnID)?settedValues.find(it=>it.columnID==item.columnID).value:''}
                              required={item.required ? true : false}
                              setValue={(e)=>{
                                if(settedValues.find(st=>{
                                  if(st.columnID==item.columnID){
                                    return true
                                  }
                                })){
                                  let newSettedValues=settedValues.map(st=>{
                                    if(st.columnID==item.columnID){
                                      st.value=e.target ? e.target.value : e
                                    }
                                    return st
                                  })
                                  setSettedValues(newSettedValues)
                                }else{
                                  setSettedValues([...settedValues,{columnID:item.columnID,value:e.target ? e.target.value : e}])
                                }
                              }}
                              placeholder={item.displayName} 
                              disabled={!edit}
                              children={item.valueType=='combo' && item.values.length>0 ? item.values.map(value=>value.value) : undefined}
                              {...item.props}
                              />
                            </div>
                          )
                        }
                      })
                    :
                    <></>
                  }
                  {
                    edit ? 
                    <div className='addButton' style={{right:'10%',bottom:5}}>
                      <Button ref={buttonRef} type='submit' color="primary" variant="contained">Kaydet</Button> 
                    </div>
                    
                    : null
                  }
                </AccordionForm>
                </>
              }
                
            </AccordionDetails>
          </Accordion>
          
        
          
          </form>
    )
  }
  return (
    <>
      {
        props.id ? 
        <></>
        :
        <Menu/>
      }
      <Helmet>
        <title>Grup Kartı</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Ekipman Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />
      {
        props.id ? 
        <>
        {
          getDetailContent()
        }
        </>
        :
        <Content sidebar={sidebar} style={{marginBottom:props.id ? -350 : null}}>
        
          <ContentForm>
            
            {
              getDetailContent()
            }

          </ContentForm>

        </Content>
      }
    </>
  )
}
