import React,{useState,useLayoutEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faArrowDown, faUserShield, faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom';
import EditButton from '../../components/Form/EditButton'
import axios from 'axios'
import {AccordionForm} from '../../styles/Content'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet';

export default function UserDetail(props) {  

  const {errorPlay,successPlay}= useSoundEffect()

  const buttonRef=useRef(null)
  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)
  const history=useHistory()

  const [name,setName]=useState()
  const [username,setUserName]=useState()
  const [date,setDate]=useState()

  const [user,setUser]=useState(null)
  const [groupNames,setGroupNames]=useState([])
  const [groups,setGroups]=useState([])
  const [selectedGroup,setSelectedGroup]=useState('')
  let {id} = useParams()
  id=id==undefined ? props.id : id
  const getGroups=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_USER_GROUPS,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(res=>{
      setGroupNames(res.data.data.map(item=>item.name))
      setGroups(res.data.data)
      setSelectedGroup((res.data.data.find(item=>item.id==user.userGroupID)).name)
    })
    .catch(err=>{
      console.log(err)
    })
  }
  const getUser=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_USER.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      setUser(response.data.data)
      setName(response.data.data.name)
      setDate(response.data.data.registerDate)
      setUserName(response.data.data.username)
      setEdit(false)
    })
    .catch(res=>{
      // console.log(res.response)

    })
  }

  useLayoutEffect(() => {
    if(user===null){
      getUser()
    }else if(groups.length===0){
      getGroups()
    }
  }, [user])

  

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [updateDialog,setUpdateDialog]=useState(false)
  const [updateConfirm,setUpdateConfirm]=useState(false)

  
  const confirmDelete=async()=>{
    let set=await deleteUser(true)
  }
  const confirmUpdate=async()=>{
    let set=await updateUser(true)
  }
  const deleteUser=async(confirmedDelete=false)=>{
    const seter=await setEdit(false)
    if(deleteConfirm==false && confirmedDelete!=true ){
      setDeleteDialog(true)
      return
    }else{
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'post',
      url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_DELETE_USER.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      successPlay()
      history.push('/kullanici/liste')
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  
  }
  const updateUser=(e)=>{
    
    if(edit){
      if(e!=true){
        e.preventDefault()
        if(deleteConfirm==false){
          setUpdateDialog(true)
          return
        }
      }else{
        setUpdateConfirm(false)
        setUpdateDialog(false)
      }
      if(name!=''){
        const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
        axios({
          method:'post',
          url:process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_UPDATE_USER.replace('-id-',id)),
          data:{
            name:name,
            personelID:user.personelID,
            userGroupID:(groups.find(item=>item.name==selectedGroup)).id,
          },
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          }
        })
        .then(response=>{
          successPlay()
          setEdit(false)
          toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        })
        .catch(error=>{
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
        
        
      }else{
        errorPlay()
        toast.error('Tüm alanları doldurmalısınız.')
      }
      
    }else{
      setEdit(true)
    }
    
  }

  const reloadData=async()=>{
    setEdit(false)
    let datas=await getUser()
    datas = await getGroups()
  }
  const getUserDetailContent=()=>{
    return (
      <form onSubmit={updateUser}>
          <Title text={<><FontAwesomeIcon icon={faUser} /> Kullanıcı Kartı</>} icon={
          <>
          <EditButton extras={[{text:'Kullanıcıyı Sil',styles:{marginRight:20,backgroundColor:'#ff7979',color:'#fff'},icon:<FontAwesomeIcon icon={faTimesCircle} />,onClick:deleteUser}]} cancel={reloadData}  edit={edit} clicker={updateUser} />
          </>} />
          <div style={{width:'105%'}}>
            <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara"
            inputClass='search-input' 
            rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } />
          </div>

          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Kullanıcı Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                  {/* {JSON.stringify(user)} */}
                  <div className='input-group' style={{width:'44%'}} >
                    <span>Tam Adı :</span>
                    <FormInput value={name} setValue={setName} mainStyle={{width:'43%'}} disabled={!edit} nomargin placeholder="Adı"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}}>
                    <span>Kullanıcı Adı :</span>
                    <FormInput value={username}  disabled nomargin mainStyle={{width:'43%'}} placeholder="Kullanıcı Adı"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}}>
                    <span>Kayıt Tarihi :</span>
                    <FormInput value={date}  disabled nomargin mainStyle={{width:'43%'}} placeholder="Kayıt Tarihi"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}}>
                    <span>Kullanıcı Grubu :</span>
                    <FormInput value={selectedGroup} disabled={!edit} children={groupNames} setValue={setSelectedGroup}  placeholder="Kullanıcı Grubu" nomargin mainStyle={{width:'43%'}} type='combo'  />
                  </div>
                
              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          {
            edit ? 
            <div className='addButton'>
              <Button ref={buttonRef} type='submit' color="primary" variant="contained">Kaydet</Button> 
            </div>
            
            : null
          }
          </form>
    )
  }
  return (
    <>
      {
        props.id ? 
        <>
        </>
        :
        <Menu/>
      }
      <Helmet>
        <title>Kullanıcı Kartı</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Kullanıcı Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Dialog
        open={updateDialog}
        setOpen={setUpdateDialog}
        setConfirm={confirmUpdate}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Kullanıcı Güncelleme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      {
        props.id ?
        getUserDetailContent()
        :
        <Content sidebar={sidebar}>
        
          <ContentForm>
            
            {
              getUserDetailContent()
            }

          </ContentForm>

        </Content>
      }
    </>
  )
}
