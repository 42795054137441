import React,{useState,useRef,useEffect} from 'react';
import {CalculatorContainer} from '../../styles/Calculator'
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Draggable from 'react-draggable';
export default function Calculator({close}) {

  const valRef=useRef(null)

  const buttons=[['C','±','%',<FontAwesomeIcon icon={faArrowLeft} />],['7','8','9','/'],['4','5','6','*'],['1','2','3','+'],['0','.','=','-']]
  const operators=['/','*','+','-','%','±']
  const [open, setOpen] = useState(false);
  const [value,setValue] = useState('');

  const [numberOne,setNumberOne] = useState(0)
  const [numberTwo,setNumberTwo] = useState(0)

  const [presult,setPresult] = useState(null)

  const [currentProcess,setCurrentProcess] = useState(null)

  const [history,setHistory]=useState('')

  const finallyCalculate=async(two=0)=>{
    if(currentProcess=='+'){
      const result=await numberOne*1+two*1
      setHistory(history+two+' = '+result)
      setPresult(result)
      setCurrentProcess(null)
    }
  }

  const resetAll=()=>{
    setValue('')
    setNumberOne(0)
    setNumberTwo(0)
    setHistory('')
    setPresult(null)
  }

  const calc=async(process='=')=>{

    if(process=='+'){
      if(numberOne!=0){
        calc('=')
      }else{
        setNumberOne(parseFloat(value))
        setValue('')
        setHistory(history+' '+parseFloat(value)+' + ')
        setCurrentProcess('+')
      }
    }else if(process=='-'){
    }else if(process=='*'){
    }else if(process=='/'){
    }else{
      setHistory(history+' '+parseFloat(value))
      finallyCalculate(parseFloat(value))
    }

  }

  const handleChange=(e,mode=false)=>{
    // console.log(e);
    if(presult!=null){
      resetAll()
      setValue('')
    }
    if(mode==false){
      //setValue(e.target.value)
      if(operators.includes(e.target.value.replace(value,"")) && operators.includes(value[value.length-1])){
        setValue(e.target.value.slice(0,-2)+e.target.value.slice(-1))
        //console.log(e.target.value.slice(0,-2));
      }else{
        setValue(e.target.value)
      }
    }else{
      switch (e.length<2 ? e : e.substr(e.length-1)) {
        case 'C':
          resetAll()
          break;
        case 'DEL':
          setValue(value.slice(0,-1))
          break;
        
        case '+':
          calc('+')
          break;
        case '-':
          calc('-')
          break;
        case '*':
          calc('*')
          break;
        case '/':
          calc('/')
          break;
        case '=':
          calc()
          break;

        
        default:
          setValue(value+e)
          break;
      }
      
    }
    valRef.current.scrollIntoView()
    
  }
  return (
    <CalculatorContainer history={open}>
      <Draggable defaultPosition={{ x:  -250, y: 250 }}>
      <div className='calc'>
        <div className='head'>
          <Button onClick={()=>setOpen(!open)} color="primary"><FontAwesomeIcon icon={faBars} /></Button>
          <b className='noselect'>Dehaculator</b>
          <Button  color="primary" onClick={()=>close()} ><FontAwesomeIcon icon={faTimes} /></Button>
        </div>
        <div className='body'>
          <div className='history'>
            aasd
          </div>
          <div className='display'>
            {history}
          </div>
          <input ref={valRef} type='text' value={presult!=null ? presult : value} onChange={handleChange} />
          <div className='buttons'>

            {
              buttons.map((item,index)=>{
                return (
                  <div key={'item-'+index}>
                    {item.map((btn,index2)=>{
                      return(
                        <Button onClick={()=>handleChange(typeof btn=='object' ? 'DEL' : btn,true)}  key={'btn-'+index2}>{btn}</Button>
                      )
                    })}
                  </div>
                )
              })
            }
            
          </div>
        </div>
      </div>
      </Draggable>
    </CalculatorContainer>
  )
}
