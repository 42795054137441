import React from 'react'
import Button from '@material-ui/core/Button';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import {  faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Excel(props) {
  const saveBtn=React.useRef(null)
  const handleClick=(e)=>{
    document.querySelector('.excel').click()
  }
  return (
    <>
    <Button onClick={handleClick} variant='contained' style={{marginLeft:15}}> <FontAwesomeIcon icon={faFileExport} /> Excel</Button>
      <ReactHTMLTableToExcel  
            className={'hidden excel'}
            table="emp"  
            filename="mami"  
            sheet="Sheet"  
            buttonText="Export excel" 
          />
      <div className='hidden'>

          <table id="emp" className="table">  
            <thead>  
              <tr>  
                {
                  props.columns.map((item,index)=>{
                    return(
                      <th key={'tab-id-'+index} style={{minWidth:20}}>{item.name}</th>
                    )
                  })
                }
              </tr>  
            </thead>  
            <tbody>   
              {
                props.data.map((item,index)=>{
                  return(
                    <tr key={'bat-id-'+index} >
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.createDate}</td>
                    </tr>
                  )
                })
              }
            </tbody>  
          </table>  
          
      </div>
      
      
      </>
  )
}
