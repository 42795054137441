import React,{useState} from 'react'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
export default function Box({item}) {
  const [open,setOpen]=useState(false)
  
  const history=useHistory()

  const jumpTo=(route,parent=null)=>{
    if(route.props!=undefined && route.props.items!=undefined && route.props.items.length>0){
      setOpen(true)
    }else{
      if(parent!=null){
        history.push(parent.path+route.path)
      }else{
        history.push(route.path)
      }
    }
  }

  return (
    <>
    {
      open ?
      <>
      <ul>
        <li>
          <h2 onClick={()=>setOpen(false)}>{item.icon}{item.label}  <span> <FontAwesomeIcon icon={faTimes} /> </span></h2>
        </li>
        {
          item.props.items.map((e,index)=>{
            return (
              <li key={index}>
                <Button variant='extended' onClick={()=>{jumpTo(e,item)}}>{e.label}</Button>
              </li>
            )
          })
        }
      
      </ul>
      </>
      :
      <Button onClick={()=>jumpTo(item)} variant='extended' className='item-box' >
        <span>{item.icon}</span>
        <h4>{item.label}</h4>
      </Button>
    }
    </>
  )
}
