import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,  faUserPlus } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import ThreeDot from '../../components/Form/ThreeDot'
import { Link,useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Radio from '@mui/material/Radio';

import {useSoundEffect} from '../../components/Content/Sounds' 
import { Helmet } from 'react-helmet'
export default function UserAdd() {

  const {successPlay,errorPlay}=useSoundEffect()
  const [selectedUser,setSelectedUser]=useState()
  const columns = [
    {
      name:'',
      selector:row=><Radio checked={selectedUser==row.id} onChange={(e)=>setSelectedUser(row.id)} color="primary" />
    },
    {
        name: 'Adı Soyadı',
        selector: row => row.fullName,
        sortable: true,
    },
    {
        name: 'Kullanıcı Adı',
        selector: row => row.username,
        sortable: true,
    },
  ];

  const [personels,setPersonels]=useState([])
  const getPersonels=()=>{

    if(personels.length>0){
      return personels
    }
    axios({
      method:'get',
      url:process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_PERSONELS,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      setPersonels(response.data.data)
    })
    .catch(error=>{
      toast.error(error.response.data.message)
      
    })
    
  }
  const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
  const sortIcon = <FontAwesomeIcon style={{transition:'300ms all',fontWeight:'400'}}   icon={faArrowDown} />;
  
  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)
  const [name,setName]=useState('')
  

  const [loading,setLoading]=useState(false)
  
  const [groupNames,setGroupNames]=useState([])
  const [groups,setGroups]=useState([])
  const [selectedGroup,setSelectedGroup]=useState('')

  const getGroups=async()=>{
    if(groups.length<1){
      axios({
        method:'get',
        url: process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_GET_USER_GROUPS,
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
      .then(res=>{
        setGroupNames(res.data.data.map(item=>item.name))
        setGroups(res.data.data)
        
      })
      
    }
  }
  getGroups()
  getPersonels()
  const addUser=async(e)=>{
    e.preventDefault()
      //do something else
    
    // setLoading(true)
    if(name!='' && groups.length>0 && selectedUser){

      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      const finallyGroup=groups.find(item=>item.name==selectedGroup)
      if(finallyGroup){
        axios({
          method:'post',
          url: process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_CREATE_USER,
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          data:{
            name:name,
            userGroupID:finallyGroup.id,
            personelID:selectedUser
          }
        })
        .then(res=>{
          toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
          successPlay()
          setName('')
          setSelectedGroup('')
          setSelectedUser(null)
        })
        .catch(error=>{
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
      }else{
        toast.update(loader, { render: 'Geçerli bir kullanıcı grubu seçmelisiniz', type: "error",isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        errorPlay()
        return
      }
    }else{
      errorPlay()
      setLoading(false)
      toast.error('Tüm alanları doldurmalısınız')
    }
  }
  
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Kullanıcı Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        <ContentForm>
          <form onSubmit={addUser}>
          <Title text={<><FontAwesomeIcon icon={faUserPlus} /> Yeni Kullanıcı Oluştur</>} />
          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Kullanıcı Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                
                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={name} setValue={setName} nomargin={true} placeholder="Adı" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='text'  />
                  </div>

                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={selectedGroup} children={groupNames} setValue={setSelectedGroup} nomargin={true} placeholder="Kullanıcı Grubu" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='combo'  />
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Personel Listesi</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <DataTable
                  pagination
                  responsive
                  paginationComponentOptions={{ noRowsPerPage: true }}
                  sortIcon={sortIcon}
                  defaultSortFieldId={1}
                  onRowClicked={(e,rowData)=>{
                    setSelectedUser(e.id)
                  }}
                  highlightOnHover
                  pointerOnHover
                  noDataComponent={<div style={{padding:20}}><Sentry size={34} /></div>}
                  columns={columns}
                  data={personels}
              />

            </AccordionDetails>
          </Accordion>

          
          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Kullanıcı Oluştur'}</Button>
          </div>



          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}
